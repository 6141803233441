import axios from "axios";

const GetDiscountsCategoryLimitsList = ({ token, admin_id, location_id, category_id, show_no_location = false, page = 1, size = 25 }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/discounts/category_limits`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        "administration": admin_id
      },
      params: {
        'location_id': location_id,
        'category_id': category_id,
        'show_no_location': show_no_location,
        'page': page,
        'size': size
      },
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default GetDiscountsCategoryLimitsList