import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import { DiscountRelationProductFormFields } from "./discountFormFields";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import { validateFormObject } from "../../helpers/validateFormObject";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import HandleOnChangeMultiObjectField from "../../helpers/handleOnChangeMultiObjectFields";
import AddObject from "../../helpers/addObject";
import RemoveObject from "../../helpers/removeObject";
import HandleOnChangeCheckbox from "../../helpers/handleOnChangeCheckbox";
import CheckNewObjects from "../../helpers/checkNewObjects";
import { DiscountLimitCalculations, DiscountLimitTypes, DiscountSettingsCalculations, DiscountSettingsTypes } from "../../selectfieldchoices/discountselect.mjs";
import useHandleError from "../../customhooks/useHandleError";
import HandleOnChangeMultiObjectSingleSelectFieldWithValue from "../../helpers/handleOnChangeMultiObjectSingleSelectFieldWithValue";
import PostDiscountsRelationProducts from "../../actablueAPI/discount/PostDiscountsRelationProducts";
import { setActiveTab } from "../../redux/dataSlices/formTabSlice";
import DiscountRelationProductForm from "../../components/discountRelations/discountRelationProductForm";

const DiscountRelationProductAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState(0);
  const [discountRelationProduct, setDiscountRelationProduct] = useState({
    administration_id: admin_id,
    location_id: location_id,
    discount_settings: { active: true },
    discount_values: [],
    discount_limits: []
  });
  const [checkedList, setCheckedList] = useState([]);
  const [invalidFields, setInvalidFields] = useState([]);
  const [discountValuesInvalid, setDiscountValuesInvalid] = useState(false);
  const [discountLimitsInvalid, setDiscountLimitsInvalid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitDisabledReason, setSubmitDisabledReason] = useState("");
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newDiscountRelationProduct = HandleOnChange({ event: event, object: discountRelationProduct })
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onChangeDiscountValues = (event) => {
    let newDiscountRelationProduct = HandleOnChangeMultiObjectField({ event: event, object: discountRelationProduct, field: 'discount_values' })
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onChangeDiscountLimits = (event) => {
    let newDiscountRelationProduct = HandleOnChangeMultiObjectField({ event: event, object: discountRelationProduct, field: 'discount_limits' })
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onChangeSelect = (event, selectFieldId) => {
    let newDiscountRelationProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: discountRelationProduct, selectfield: selectFieldId })
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onRelationChange = (event) => {
    let newDiscountRelationProduct = JSON.parse(JSON.stringify(discountRelationProduct));
    newDiscountRelationProduct.relation_id = { id: event?.value, description: event?.label }
    if (event?.value === undefined || event?.label === undefined) newDiscountRelationProduct.relation_id = null
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onProductChange = (event) => {
    let newDiscountRelationProduct = JSON.parse(JSON.stringify(discountRelationProduct));
    newDiscountRelationProduct.product_id = { id: event?.value, description: event?.label }
    if (event?.value === undefined || event?.label === undefined) newDiscountRelationProduct.product_id = null
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onAddObject = (event) => {
    let newId = id + 1
    setId(newId)
    let newDiscountRelationProduct = AddObject({ event: event, object: discountRelationProduct, id: newId })
    if (event?.target?.name === 'discount_limits') newDiscountRelationProduct[event?.target?.name].find(obj => obj.id === newId.toString()).active = true;
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onRemoveObjects = (event) => {
    let newDiscountRelationProduct = RemoveObject({ event: event, object: discountRelationProduct, checkedList: checkedList })
    setDiscountRelationProduct(newDiscountRelationProduct)
    setCheckedList([])
  }

  const onChangeCheckbox = (event) => {
    let newCheckedList = HandleOnChangeCheckbox({ event: event, object: checkedList })
    setCheckedList(newCheckedList)
  }

  const onTypeChange = (event) => {
    let newDiscountRelationProduct = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: discountRelationProduct, selectfield: 'type', field: 'discount_limits' })
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onCalculationChange = (event) => {
    let newDiscountRelationProduct = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: discountRelationProduct, selectfield: 'calculation', field: 'discount_limits' })
    setDiscountRelationProduct(newDiscountRelationProduct)
  }

  const onCancel = () => {
    navigate(-1)
    dispatch(setActiveTab('main'));
  }

  const onSubmit = () => {
    const [invalid] = validateFormObject({ object: discountRelationProduct, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    let newObject = JSON.parse(JSON.stringify(discountRelationProduct));

    newObject = CheckNewObjects(discountRelationProduct)

    if (newObject?.discount_settings?.start === '') newObject.discount_settings.start = null;
    if (newObject?.discount_settings?.end === '') newObject.discount_settings.end = null;

    dispatch(setLoading(true))

    PostDiscountsRelationProducts({ token: token, admin_id: discountRelationProduct?.administration_id, data: newObject })
      .then(() => {
        navigate("/discount/relations");
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit,
      disabled: submitDisabled,
      disabledReason: submitDisabledReason
    },
    setSubmitDisabled: setSubmitDisabled,
    setSubmitDisabledReason: setSubmitDisabledReason,
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: DiscountRelationProductFormFields(),
    field: {
      onChange: onChange
    },
    data: discountRelationProduct,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === discountRelationProduct.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: null,
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: null,
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    relation_id: {
      label: 'relation_id',
      placeholder: 'Search relations...',
      noOptionsMessage: 'Type to search relations...',
      options: [],
      selected: [],
      onChange: onRelationChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    product_id: {
      label: 'product_id',
      placeholder: 'Search products...',
      noOptionsMessage: 'Type to search products...',
      options: [],
      selected: [],
      onChange: onProductChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: DiscountSettingsTypes,
      selected: [],
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    calculation: {
      label: 'calculation',
      options: DiscountSettingsCalculations,
      selected: [],
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    discount_values: {
      setInvalid: setDiscountValuesInvalid,
      onChange: onChangeDiscountValues,
      add: {
        title: 'Add',
        type: 'button',
        name: 'discount_values',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'discount_values',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
      },
      selected: checkedList
    },
    discount_limits: {
      setInvalid: setDiscountLimitsInvalid,
      onChange: onChangeDiscountLimits,
      add: {
        title: 'Add',
        type: 'button',
        name: 'discount_limits',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'discount_limits',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
      },
      selected: checkedList,
      type: {
        label: 'type',
        options: DiscountLimitTypes,
        onChange: onTypeChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      },
      calculation: {
        label: 'calculation',
        options: DiscountLimitCalculations,
        onChange: onCalculationChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      }
    },
  };

  const addCard = {
    size: 12,
    title: "Discount Relation Product add",
    data: <DiscountRelationProductForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Discount Relation"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (discountLimitsInvalid || discountValuesInvalid) {
      setSubmitDisabled(true);
      return
    }
    setSubmitDisabled(false)
    // eslint-disable-next-line
  }, [discountLimitsInvalid, discountValuesInvalid])

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default DiscountRelationProductAdd;