import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import { DiscountRelationLimitFormFields } from "./discountFormFields";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import { validateFormObject } from "../../helpers/validateFormObject";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { DiscountLimitCalculations, DiscountLimitTypes } from "../../selectfieldchoices/discountselect.mjs";
import useHandleError from "../../customhooks/useHandleError";
import DiscountRelationLimitForm from "../../components/discountRelations/discountRelationLimitForm";
import PostDiscountsRelationLimits from "../../actablueAPI/discount/PostDiscountsRelationLimits";
import { setActiveTab } from "../../redux/dataSlices/formTabSlice";

const DiscountRelationLimitAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [discountRelationLimit, setDiscountLimitRelation] = useState({
    administration_id: admin_id,
    location_id: location_id,
    discount_limit: { active: true }
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newDiscountRelationLimit = HandleOnChange({ event: event, object: discountRelationLimit })
    setDiscountLimitRelation(newDiscountRelationLimit)
  }

  const onChangeSelect = (event, selectFieldId) => {
    let newDiscountRelationLimit = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: discountRelationLimit, selectfield: selectFieldId })
    setDiscountLimitRelation(newDiscountRelationLimit)
  }

  const onRelationChange = (event) => {
    let newDiscountRelationLimit = JSON.parse(JSON.stringify(discountRelationLimit));
    newDiscountRelationLimit.relation_id = { id: event?.value, description: event?.label }
    if (event?.value === undefined || event?.label === undefined) newDiscountRelationLimit.relation_id = null
    setDiscountLimitRelation(newDiscountRelationLimit)
  }

  const onCancel = () => {
    navigate(-1)
    dispatch(setActiveTab('main'));
  }

  const onSubmit = () => {
    const [invalid] = validateFormObject({ object: discountRelationLimit, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    PostDiscountsRelationLimits({ token: token, admin_id: discountRelationLimit?.administration_id, data: discountRelationLimit })
      .then(() => {
        navigate("/discount/relations");
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit,
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: DiscountRelationLimitFormFields(),
    field: {
      onChange: onChange
    },
    data: discountRelationLimit,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === discountRelationLimit.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: null,
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: null,
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    relation_id: {
      label: 'relation_id',
      placeholder: 'Search relations...',
      noOptionsMessage: 'Type to search relations...',
      options: [],
      selected: [],
      onChange: onRelationChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: DiscountLimitTypes,
      selected: [],
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    calculation: {
      label: 'calculation',
      options: DiscountLimitCalculations,
      selected: [],
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Discount Relation Limit add",
    data: <DiscountRelationLimitForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Discount Relation"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default DiscountRelationLimitAdd;

