import { useState } from "react";
import { ModifyMultipleProducts } from "./modifyMultipleProducts";
import { ProductMultiEditFormFields } from "../../portal/products/productFormFields";
import HandleOnChangeSingleSelectFieldWithValue from "../handleOnChangeSingleSelectFieldWithValue";
import HandleOnChangeJournalCategory from "../handleOnChangeJournalCategory";
import HandleOnChangeSingleSelectFieldWithObject from "../handleOnChangeSingleSelectFieldWithObject";
import { visibleChoices } from "../../selectfieldchoices/productselect.mjs";

export function MultiEditFormData({
  token,
  admin_id,
  journalCategories,
  hash_journal_categories,
  vats_all,
  hash_vats_all,
  selectedProducts,
  multiEditData,
  dispatch,
  setMultiEditData,
  toggleRefresh
}) {
  const [modalShow, setModalShow] = useState(false)

  const onSaveClick = () => {
    ModifyMultipleProducts({
      token: token,
      admin_id: admin_id,
      selectedProducts: selectedProducts,
      multiEditData: multiEditData,
      dispatch: dispatch,
      setModalShow: setModalShow,
      setMultiEditData: setMultiEditData,
      toggleRefresh: toggleRefresh
    })
  };

  const toggleModal = (event) => {
    event.stopPropagation();
    setModalShow(!modalShow)
  };
  
  const onChange = (event, selectFieldId) => {
    let newData = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: multiEditData, selectfield: selectFieldId })
    setMultiEditData(newData)
  }

  const onJournalCategoriesChange = (event) => {
    let [newData,] = HandleOnChangeJournalCategory({ event: event, object: multiEditData, hash: hash_journal_categories, deleteList: [] })
    setMultiEditData(newData)
  };

  const onVatChange = (event) => {
    let newData = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: multiEditData, selectfield: 'vat', hash: hash_vats_all })
    setMultiEditData(newData)
  };

  const multiEditformData = {
    modal: {
      title: "Product multi edit",
      show: modalShow,
      onSaveClick: onSaveClick,
      toggleModal: toggleModal,
      fields: ProductMultiEditFormFields(),
      data: multiEditData,
      selected: selectedProducts,
      journalcategories: {
        name: 'journalcategories',
        options: journalCategories,
        selected: null,
        onChange: onJournalCategoriesChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      vats: {
        label: 'vat',
        options: vats_all,
        onChange: onVatChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      active: {
        label: 'active',
        placeholder: 'Select...',
        options: visibleChoices,
        selected: [],
        onChange: onChange,
        clearable: Boolean(true),
        searchable: Boolean(false)
      },
      ask_sn: {
        label: 'ask_sn',
        placeholder: 'Select...',
        options: visibleChoices,
        selected: [],
        onChange: onChange,
        clearable: Boolean(true),
        searchable: Boolean(false)
      },
      is_weighted: {
        label: 'is_weighted',
        placeholder: 'Select...',
        options: visibleChoices,
        selected: [],
        onChange: onChange,
        clearable: Boolean(true),
        searchable: Boolean(false)
      },
      input_required: {
        label: 'input_required',
        placeholder: 'Select...',
        options: visibleChoices,
        selected: [],
        onChange: onChange,
        clearable: Boolean(true),
        searchable: Boolean(false)
      },
    }
  }

  return multiEditformData
}

