import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../../helpers/createField';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTab, setActiveTab } from '../../redux/dataSlices/formTabSlice';
import MixMatchDiscountValuesEditTable from './mixMatchDiscountValuesEditTable';
import { DiscountSettingsCalculations, DiscountSettingsTypes } from '../../selectfieldchoices/discountselect.mjs';
import MixMatchDiscountLimitsEditTable from './mixMatchDiscountLimitsEditTable';

export default function MixMatchForm(props) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);

  if (props.data.hasOwnProperty('location_id')) {
    if (props.location_id.hash) {
      props.location_id.selected = { 'value': props.data.location_id, 'label': props.location_id.hash[props.data.location_id]?.name }
    }
  }

  if (props.data.hasOwnProperty('administration_id')) {
    if (props.administration_id.hash) {
      props.administration_id.selected = { 'value': props.data.administration_id, 'label': props.administration_id.hash[props.data.administration_id]?.name }
    }
  }

  if (props?.data?.discount_settings?.hasOwnProperty('type')) {
    props.type.selected = { 'value': props?.data?.discount_settings?.type, 'label': DiscountSettingsTypes.find(type => type.id === props?.data?.discount_settings?.type)?.name }
  }

  if (props?.data?.discount_settings?.hasOwnProperty('calculation')) {
    props.calculation.selected = { 'value': props?.data?.discount_settings?.calculation, 'label': DiscountSettingsCalculations.find(calculation => calculation.id === props?.data?.discount_settings?.calculation)?.name }
  }

  if (props?.data?.hasOwnProperty('product_ids')) {
    props?.data?.product_ids?.forEach(product_id => {
      props?.product_ids?.selected.push({ 'value': product_id?.id, 'label': product_id?.description })
    });
    if (!props?.data?.product_ids || props?.data?.product_ids?.length <= 0) props.product_ids.selected = [];
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id} onClick={() => dispatch(setActiveTab(field.id))}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        embeddedfield.invalid = props.invalidFields.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateField(field, props))
    }
  });

  const discountValuesTabIndex = formTabs.findIndex(tab => tab.key === 'discount_values');
  const discountValuesContentIndex = formTabContent.findIndex(tab => tab.key === 'discount_values');
  const discountLimitsTabIndex = formTabs.findIndex(tab => tab.key === 'discount_limits');
  const discountLimitsContentIndex = formTabContent.findIndex(tab => tab.key === 'discount_limits');

  formTabs[discountValuesTabIndex] = (
    <Nav.Item key='discount_values' onClick={() => dispatch(setActiveTab('discount_values'))}>
      <Nav.Link eventKey='discount_values'>
        {translate('Discount Values')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent[discountValuesContentIndex] = (
    <Tab.Pane eventKey='discount_values' key='discount_values'>
      <div className='tab-container'>
        <MixMatchDiscountValuesEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  formTabs[discountLimitsTabIndex] = (
    <Nav.Item key='discount_limits' onClick={() => dispatch(setActiveTab('discount_limits'))}>
      <Nav.Link eventKey='discount_limits'>
        {translate('Discount Limits')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent[discountLimitsContentIndex] = (
    <Tab.Pane eventKey='discount_limits' key='discount_limits'>
      <div className='tab-container'>
        <MixMatchDiscountLimitsEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main" activeKey={activeTab}>
          <Nav variant="tabs">
            <Nav.Item onClick={() => dispatch(setActiveTab("main"))}>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick} disabled={props.submit.disabled}>{translate(props.submit.title)}</Button>
      {props.submit.disabled && <p style={{ marginTop: 10, color: 'red', fontWeight: '600' }}>{props.submit.disabledReason}</p>}
    </>
  );
}