import { useEffect } from "react";
import { Col, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import SingleSelectCreateField from "../singleselectcreateField";
import {
  DiscountLimitCalculations,
  DiscountLimitTypes,
} from "../../selectfieldchoices/discountselect.mjs";

const isInvalidField = (value) => {
  return value === null || value === undefined || value === "";
};

export default function MixMatchDiscountLimitsEditTable(props) {
  const { t: translate } = useTranslation();

  let rows = [];
  let selected = props?.discount_limits?.selected || [];

  useEffect(() => {
    if (!props?.data?.hasOwnProperty(props?.discount_limits?.add?.name)) {
      return;
    }

    let anyFieldInvalid = props?.data?.[props?.discount_limits?.add?.name]?.some(
      (element) =>
        isInvalidField(element?.type) ||
        isInvalidField(element?.calculation) ||
        isInvalidField(element?.value)
    );

    props?.discount_limits?.setInvalid(anyFieldInvalid);

    if (anyFieldInvalid) {
      props.setSubmitDisabledReason("Invalid field in Discount Limits");
    }
    // eslint-disable-next-line
  }, [props?.data, props?.discount_limits]);

  if (props?.data?.hasOwnProperty(props?.discount_limits?.add?.name)) {
    props?.data?.[props?.discount_limits?.add?.name]?.forEach((element) => {
      rows.push(
        <tr className="product-row" key={element.id}>
          <td>
            <Form.Check
              onChange={props?.discount_limits?.check?.onChange}
              id={element.id}
              elementid={element.id}
            />
          </td>
          <td>
            <Col>
              <SingleSelectCreateField
                name="type"
                options={props?.discount_limits?.type?.options}
                onChange={props?.discount_limits?.type?.onChange}
                clearable={false}
                searcheable={true}
                selected={{
                  value: element.type,
                  label: DiscountLimitTypes.find(
                    (type) => type.id === element.type
                  )?.name,
                }}
                required={true}
                id="type"
                elementid={element.id}
                prefix="discount_limits"
                className={isInvalidField(element?.type) && `is-invalid`}
              />
            </Col>
          </td>
          <td>
            <SingleSelectCreateField
              name="calculation"
              options={props?.discount_limits?.calculation?.options}
              onChange={props?.discount_limits?.calculation?.onChange}
              clearable={false}
              searcheable={true}
              selected={{
                value: element.calculation,
                label: DiscountLimitCalculations.find(
                  (calculation) => calculation.id === element.calculation
                )?.name,
              }}
              required={true}
              id="calculation"
              elementid={element.id}
              prefix="discount_limits"
              className={isInvalidField(element?.calculation) && `is-invalid`}
            />
          </td>
          <td className="text-center">
            <Form.Check
              prefix="discount_limits"
              elementid={element.id}
              type="switch"
              name="active"
              value={Boolean(element.active)}
              onChange={props?.discount_limits?.onChange}
              id="active"
              checked={Boolean(element.active)}
            />
          </td>
          <td>
            <Form.Control
              prefix="discount_limits"
              elementid={element.id}
              type="text"
              name="value"
              placeholder={translate("Value")}
              value={element.value}
              onChange={props?.discount_limits?.onChange}
              isInvalid={isInvalidField(element?.value)}
            />
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <Table hover width="100%">
        <thead>
          <tr>
            <th />
            <th width="25%">{translate("Type")}</th>
            <th width="25%">{translate("Calculation")}</th>
            <th width="5%">{translate("Active")}</th>
            <th width="25%">{translate("Value")}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Button
        variant="primary"
        type={props?.discount_limits?.add?.type}
        onClick={props?.discount_limits?.add?.onClick}
        name={props?.discount_limits?.add?.name}
      >
        {translate(props.discount_limits.add.title)}
      </Button>
      <Button
        variant="primary"
        type={props?.discount_limits?.remove?.type}
        onClick={props?.discount_limits?.remove?.onClick}
        name={props?.discount_limits?.remove?.name}
        disabled={selected.length === 0}
      >
        {translate(props?.discount_limits?.remove?.title)}
      </Button>
    </>
  );
}
