import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getAttributeGroupsAll, getHashAttributeGroupsAll } from "../../redux/dataSlices/attributeSlice";
import { getVatsAll, setVatsAll, getHashVatsAll } from "../../redux/dataSlices/vatSlice";
import { getProductCategoriesAll, getHashProductCategoriesAll, getJournalCategoriesAll, getHashJournalCategoriesAll, setJournalCategoriesAll, setProductCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import ProductForm from "../../components/productForm"
import { ProductFormFields, ProductSupplierFormFields } from "./productFormFields.jsx"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeJournalCategory from "../../helpers/handleOnChangeJournalCategory";
import HandleOnChangeProductCategory from "../../helpers/handleOnChangeProductCategory";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import GetAllVats from "../../helpers/getAllVats";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import HandleOnChangeEans from "../../helpers/handleOnChangeEans";
import { productFilterChoices } from "../../selectfieldchoices/productselect.mjs"
import { codeTypeChoices } from "../../selectfieldchoices/producteslselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getTemplatesAll } from "../../redux/dataSlices/templateSlice";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType.jsx";
import { validateFormObject } from "../../helpers/validateFormObject.jsx";
import { setActiveTab } from "../../redux/dataSlices/formTabSlice.js";
import PostProductLinkSchap from "../../actablueAPI/shelflabels/PostProductLink.jsx";
import PostProductLinkOpticon from "../../actablueAPI/opticon/PostProductLink.jsx";
import AddObject from "../../helpers/addObject.jsx";
import RemoveObject from "../../helpers/removeObject.jsx";
import HandleOnChangeCheckbox from "../../helpers/handleOnChangeCheckbox.jsx";
import HandleOnChangeMultiObjectField from "../../helpers/handleOnChangeMultiObjectFields.jsx";
import PostProductSupplier from "../../actablueAPI/products/PostProductSupplier.jsx";
import { getHashPrintersAll, getHashScreensAll, getHashSortCategoriesAll, getPrintersAll, getScreensAll, getSortCategoriesAll, setPrintersAll, setScreensAll, setSortCategoriesAll } from "../../redux/dataSlices/kitchenmanagerSlice.js";
import PostKitchenManagerProduct from "../../actablueAPI/kitchenmanager/PostKitchenManagerProduct.jsx";
import GetAllPrinters from "../../actablueAPI/kitchenmanager/GetAllPrinters.jsx";
import GetAllCategories from "../../actablueAPI/kitchenmanager/GetAllCategories.jsx";
import DelProductIdFromCategory from "../../actablueAPI/products/DelProductIdFromCategory.jsx";
import PostProduct from "../../actablueAPI/products/PostProduct.jsx";
import PostProductSpecifications from "../../actablueAPI/products/PostProductSpecifications.jsx";
import GetAllScreens from "../../actablueAPI/kitchenmanager/GetAllScreens.jsx";
import HandleOnChangeMultiStringArray from "../../helpers/handleOnChangeMultiStringArray.jsx";
import useHandleError from "../../customhooks/useHandleError.jsx";
import GetRelationsByTypeAll from "../../actablueAPI/relations/GetRelationsByTypeAll.jsx";
import { setRelationsAll } from "../../redux/dataSlices/relationSlice.js";
import PostImage from "../../actablueAPI/cdn/PostImage.jsx";

const ProductAdd = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const journalCategories = useSelector(getJournalCategoriesAll)
  const hash_journal_categories = useSelector(getHashJournalCategoriesAll)
  const productpageCategories = useSelector(getProductCategoriesAll)
  const hash_productpage_categories = useSelector(getHashProductCategoriesAll)
  const vats_all = useSelector(getVatsAll)
  const hash_vats_all = useSelector(getHashVatsAll)
  const attributeGroups = useSelector(getAttributeGroupsAll)
  const hash_attributegroups = useSelector(getHashAttributeGroupsAll)
  const templates = useSelector(getTemplatesAll)
  const kmPrinters = useSelector(getPrintersAll);
  const hash_printers = useSelector(getHashPrintersAll);
  const sortCategories = useSelector(getSortCategoriesAll);
  const hash_sort_categories = useSelector(getHashSortCategoriesAll);
  const screens = useSelector(getScreensAll);
  const hash_screens = useSelector(getHashScreensAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const uniqueSKU = `${Date.now()}`
  const [product, setProduct] = useState({ administration_id: admin_id, location_id: location_id, active: true, type: 'article', sku_code: uniqueSKU })
  const [deleteCategories, setDeleteCategories] = useState([])
  const [checkedList, setCheckedList] = useState([])
  const [invalidFields, setInvalidFields] = useState([])
  const [supplierInvalidFields, setSupplierInvalidFields] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [supplier, setSupplier] = useState({ admin_id: admin_id })
  const [supplierModalShow, setSupplierModalShow] = useState(false)
  const [relationLoading, setRelationLoading] = useState(false)
  const [id, setId] = useState(-1000)
  const [formFields, setFormFields] = useState(ProductFormFields());
  const [saveDisabled, setSaveDisabled] = useState(false);
  const navTitle = "Product add"

  const onChange = (event) => {
    let newProduct = HandleOnChange({ event: event, object: product, admin_id: admin_id })
    setProduct(newProduct)
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = async () => {
    const [invalid] = validateFormObject({ object: product, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    let newProduct = JSON.parse(JSON.stringify(product))

    // delete product id from categories
    deleteCategories.forEach(async category => {
      await DelProductIdFromCategory({ token: token, admin_id: admin_id, category_id: category.id, product_id: product.id })
        .then(() => {
          delete deleteCategories[category]
        })
    });

    if (suppliers) delete newProduct?.suppliers

    if (newProduct?.price_incl_vat === 0) newProduct.price_excl_vat = 0;

    // post product
    PostProduct({ token: token, admin_id: admin_id, data: newProduct })
      .then(async response => {
        if (product.hasOwnProperty('esl') && Object.keys(product?.esl).length > 0 && product?.esl?.hasOwnProperty('esl_barcode')) { // We put this in the product post then response because we need the ID from the response
          if (scopes.schapkaartjes) {

            const opticonData =
            {
              'location_id': location_id,
              'product_id': response?.data?.id,
              'product_name': response?.data?.name,
              'esl_barcode': product?.esl?.esl_barcode
            }

            const schapData =
            {
              'product_id': response?.data?.id,
              'print_template_id': product?.esl?.print_template_id,
              'esl_template_id': product?.esl?.esl_template_id,
              'code_type': product?.esl?.code_type,
              'admin_id': admin_id,
              'location_id': location_id
            }

            if (opticonData?.esl_barcode) {
              await PostProductLinkOpticon({ token: token, data: opticonData })
                .catch(error => {
                  console.error(error)
                });
            }

            if (schapData?.product_id) {
              await PostProductLinkSchap({ token: token, data: schapData })
                .catch(error => {
                  console.error(error)
                });
            }
            delete product.esl
          }
        }

        if (product.hasOwnProperty('kitchenmanager') && Object.keys(product?.kitchenmanager).length > 0) {

          if (scopes.sortcategories) {
            if ((product?.kitchenmanager?.printer && product?.kitchenmanager?.category) ||
              (product?.kitchenmanager?.screens && product?.kitchenmanager?.category) ||
              (product?.kitchenmanager?.printer && product?.kitchenmanager?.category && product?.kitchenmanager?.screens)) {
              product.kitchenmanager.has_multiple_groups = product.categories.filter(item => item.type === 'PRODUCTPAGE').length > 1;
              product.kitchenmanager.product_id = response?.data?.id
              product.kitchenmanager.name = product?.name;
              product.kitchenmanager.admin_id = admin_id
              product.kitchenmanager.location_id = location_id
              if (!product?.kitchenmanager?.screens) product.kitchenmanager.screens = [];
              await PostKitchenManagerProduct({ token: token, data: product?.kitchenmanager })
                .catch(error => {
                  console.error(error)
                });
            }
          }
        }

        if (suppliers) {
          suppliers.forEach(async (supplier) => {
            let newSupplier = JSON.parse(JSON.stringify(supplier))
            newSupplier.product = { 'id': response?.data?.id };
            if (newSupplier?.temp) {
              delete newSupplier?.id
              delete newSupplier?.temp
            }
            await PostProductSupplier({ token: token, admin_id: admin_id, data: newSupplier })
          })
        }
      })

    // post poductspecifications
    if (product.hasOwnProperty('product_specifications')) {
      PostProductSpecifications({ token: token, admin_id: admin_id, id: product.id, data: product.product_specifications })
    }
    navigate(-1);

  };

  const onAddObject = (event) => {
    let newId = id + 1
    setId(newId)
    let newProduct = AddObject({ event: event, object: product, id: newId })
    setProduct(newProduct)
  }

  const onRemoveObjects = (event) => {
    let newProduct = RemoveObject({ event: event, object: product, checkedList: checkedList })
    setProduct(newProduct)
    setCheckedList([])
  }

  const onRemoveObjectsSuppliers = (event) => {
    let newProduct = RemoveObject({ event: event, object: product, checkedList: checkedList })
    setSuppliers(newProduct?.suppliers)
    setProduct(newProduct)
    setCheckedList([])
  }

  const onChangeCheckbox = (event) => {
    let newCheckedList = HandleOnChangeCheckbox({ event: event, object: checkedList })
    setCheckedList(newCheckedList)
  }

  const onChangeAllCheckbox = (event) => {
    // const checked = event.target?.checked
    // const id = event.target?.attributes?.id?.nodeValue
    // let newCheckedList = Object.assign([], checkedList)
    // if (checked) {newCheckedList.push(id)}
    // if (!checked) {newCheckedList.pop(id)}
    // setCheckedList(newCheckedList)
  }

  const onJournalCategoriesChange = (event) => {
    let [newProduct, newDeleteCategories] = HandleOnChangeJournalCategory({ event: event, object: product, hash: hash_journal_categories, deleteList: deleteCategories })
    setProduct(newProduct)
    setDeleteCategories(newDeleteCategories)
  };

  const onProductCategoriesChange = (event) => {
    let [newProduct, newDeleteCategories] = HandleOnChangeProductCategory({ event: event, object: product, hash: hash_productpage_categories, deleteList: deleteCategories })
    setProduct(newProduct)
    setDeleteCategories(newDeleteCategories)
  };

  const onVatChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: product, selectfield: 'vat', hash: hash_vats_all })
    setProduct(newProduct)
  };

  const onEanChange = (event) => {
    let newProduct = HandleOnChangeEans({ event: event, object: product })
    setProduct(newProduct)
  };

  const onImageChange = (event) => {
    setSaveDisabled(true);
    const newFormFields = [...formFields];
    newFormFields.find((field) => field?.id === 'thumbnail').disabled = true
    newFormFields.find((field) => field?.id === 'new_thumbnail').disabled = true
    setFormFields(newFormFields);

    // check extension of file
    const extension = event.target?.files?.[0]?.name?.split('.')[1];
    const filesize = event.target?.files?.[0]?.size;
    const filesizeMb = filesize / 1024;
    const filesizeLimit = 1024 * 8; // 8 MB

    let isInvalid = false;
    switch (true) {
      case (filesizeMb > filesizeLimit):
        HandleError({
          error: "Image size exceeds the filesize limit (8MB)",
          showPopup: true,
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
        isInvalid = true;
        break;

      case (extension.toLowerCase() !== 'png'):
        HandleError({
          error: "Uploaded file must be .PNG",
          showPopup: true,
          variant: 'warning',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
        isInvalid = true;
        break;
      default:
        isInvalid = false;
        break;
    }

    if (isInvalid) {
      const newFormFields = [...formFields];
      newFormFields.find((field) => field?.id === 'thumbnail').disabled = false
      newFormFields.find((field) => field?.id === 'new_thumbnail').disabled = false
      setFormFields(newFormFields);
      setSaveDisabled(false);
      event.target.value = "" // Reset the file upload field
      return;
    }

    if (extension.toLowerCase() === 'png') {
      let newProduct = JSON.parse(JSON.stringify(product))
      newProduct.thumbnail = "Uploading image please wait..."
      setProduct(newProduct)
      PostImage({ token: token, admin_id: admin_id, location_id: location_id, file: event.target?.files?.[0] })
        .then(response => {
          let newProduct = JSON.parse(JSON.stringify(product))
          newProduct.thumbnail = response?.data?.image_url
          setProduct(newProduct)
        })
        .catch(error => {
          HandleError({ error: error })
          let newProduct = JSON.parse(JSON.stringify(product))
          newProduct.thumbnail = null
          setProduct(newProduct)
        })
        .finally(() => {
          const newFormFields = [...formFields];
          newFormFields.find((field) => field?.id === 'thumbnail').disabled = false
          newFormFields.find((field) => field?.id === 'new_thumbnail').disabled = false
          setFormFields(newFormFields);
          setSaveDisabled(false);
          event.target.value = "" // Reset the file upload field
        })
    }
  }

  const onAttributeGroupChange = (event) => {
    let newProduct = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: product, selectfield: 'attributes', hash: hash_attributegroups })
    setProduct(newProduct)
  };

  const onTypeChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'type' })
    setProduct(newProduct)
  }

  const onTemplateChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'esl_template_id', prefix: 'esl' })
    setProduct(newProduct)
  }

  const onPrintTemplateChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'print_template_id', prefix: 'esl' })
    setProduct(newProduct)
  }

  const onCodeChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'code_type', prefix: 'esl' })
    setProduct(newProduct)
  }

  const onChangeNutrient = (event) => {
    let newProduct = HandleOnChangeMultiObjectField({ event: event, object: product, field: 'nutrients' })
    setProduct(newProduct)
  }

  const toggleSupplierModal = () => {
    setSupplierModalShow(!supplierModalShow)
    setSupplier({ admin_id: admin_id });
  }

  const onPrinterChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'printer', prefix: 'kitchenmanager' })
    setProduct(newProduct)
  }

  const onCategoryChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'category', prefix: 'kitchenmanager' })
    setProduct(newProduct)
  }

  const onScreensChange = (event) => {
    let newProduct = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: product, selectfield: 'screens', prefix: 'kitchenmanager' })
    if (newProduct.kitchenmanager.screens.length === 0) {
      delete newProduct.kitchenmanager.screens
    }
    setProduct(newProduct)
  }

  const onChangeKitchenManager = (event) => {
    let newProduct = HandleOnChange({ event: event, object: product, admin_id: admin_id, prefix: 'kitchenmanager' })
    setProduct(newProduct)
  }

  const saveSupplier = () => {
    const [invalid] = validateFormObject({ object: supplier, fields: formData?.suppliers?.modal?.fields, switchTab: false, dispatch: dispatch })

    if (invalid?.length > 0) {
      setSupplierInvalidFields(invalid)
      return;
    }

    let newProduct = JSON.parse(JSON.stringify(product))
    let newSuppliers = JSON.parse(JSON.stringify(suppliers));
    if (!supplier?.id) {
      let newId = id + 1;
      setId(newId);
      supplier.id = id
      supplier.temp = true
    }
    const existingIndex = newSuppliers?.findIndex(existingSupplier => existingSupplier?.id === supplier?.id)
    if (existingIndex === -1) newSuppliers.push(supplier);
    if (existingIndex !== -1) newSuppliers[existingIndex] = supplier
    newProduct.suppliers = newSuppliers
    setProduct(newProduct);
    setSuppliers(newSuppliers);
    setSupplierModalShow(false);
    setSupplierInvalidFields([]);
    setSupplier({ admin_id: admin_id });
  }

  const onChangeSupplier = (event) => {
    let newSupplier = HandleOnChange({ event: event, object: supplier })
    setSupplier(newSupplier);
  }

  const onChangeSupplierSingleSelect = (event) => {
    let newSupplier = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: supplier, selectfield: 'relation_id' })
    setSupplier(newSupplier)
  }

  const onSupplierEanChange = (event) => {
    let newSupplier = HandleOnChangeEans({ event: event, object: supplier })
    setSupplier(newSupplier)
  };

  const onSupplierAdd = () => {
    setSupplierModalShow(true);
  }

  const onSupplierEdit = (event, id) => {
    event.stopPropagation();
    setSupplier(suppliers?.find(supplier => supplier?.id === id));
    setSupplierModalShow(true);
  }

  const onChangeAllergenics = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'allergenics', change: 'change' })
    setProduct(newProduct)
  }

  const onAddAllergenicsString = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'allergenics', change: 'add' })
    setProduct(newProduct)
  }

  const onRemoveAllergenicsString = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'allergenics', change: 'remove', checkedList: checkedList })
    setProduct(newProduct)
  }

  const onChangeIngredients = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'ingredients', change: 'change' })
    setProduct(newProduct)
  }

  const onAddIngredientsString = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'ingredients', change: 'add' })
    setProduct(newProduct)
  }

  const onRemoveIngredientsString = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'ingredients', change: 'remove', checkedList: checkedList })
    setProduct(newProduct)
  }

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit,
      disabled: saveDisabled
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: formFields,
    field: {
      onChange: onChange
    },
    data: product,
    templates: templates,
    hash_printers: hash_printers,
    hash_sort_categories: hash_sort_categories,
    hash_screens: hash_screens,
    new_thumbnail: {
      onChange: onImageChange
    },
    journalcategories: {
      name: 'journalcategories',
      options: journalCategories,
      selected: [],
      onChange: onJournalCategoriesChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    productcategories: {
      name: 'productcategories',
      options: productpageCategories,
      selected: new Set(),
      onChange: onProductCategoriesChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    vats: {
      label: 'vat',
      options: vats_all,
      onChange: onVatChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    eans: {
      label: 'eans',
      options: [],
      selected: [],
      onChange: onEanChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    images: {
      label: 'images',
      options: [],
      selected: [],
      onChange: onImageChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    attributes: {
      label: 'attributes',
      options: attributeGroups,
      selected: [],
      onChange: onAttributeGroupChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: productFilterChoices,
      selected: [],
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    print_template_id: {
      label: 'print_template_id',
      options: [],
      selected: [],
      onChange: onPrintTemplateChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    esl_template_id: {
      label: 'esl_template_id',
      options: [],
      selected: [],
      onChange: onTemplateChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    printer: {
      label: 'printer',
      options: kmPrinters,
      selected: [],
      onChange: onPrinterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    category: {
      label: 'category',
      options: sortCategories,
      selected: [],
      onChange: onCategoryChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    screens: {
      label: 'screens',
      options: screens,
      selected: [],
      onChange: onScreensChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    code_type: {
      label: 'code_type',
      options: codeTypeChoices,
      selected: [],
      onChange: onCodeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    short_name: {
      onChange: onChangeKitchenManager
    },
    nutrients: {
      onChange: onChangeNutrient,
      add: {
        title: 'Add',
        type: 'button',
        name: 'nutrients',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'nutrients',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList
    },
    allergenics: {
      onChange: onChangeAllergenics,
      add: {
        title: 'Add',
        type: 'button',
        name: 'allergenics',
        onClick: onAddAllergenicsString
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'allergenics',
        onClick: onRemoveAllergenicsString
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList
    },
    ingredients: {
      onChange: onChangeIngredients,
      add: {
        title: 'Add',
        type: 'button',
        name: 'ingredients',
        onClick: onAddIngredientsString
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'ingredients',
        onClick: onRemoveIngredientsString
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList
    },
    suppliers: {
      data: suppliers,
      add: {
        title: 'Add',
        type: 'button',
        name: 'suppliers',
        onClick: onSupplierAdd
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'suppliers',
        onClick: onRemoveObjectsSuppliers
      },
      edit: {
        tag: "Edit",
        value: 'edit',
        onClick: onSupplierEdit
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      modal: {
        title: 'Product Supplier',
        data: supplier,
        show: supplierModalShow,
        toggleModal: toggleSupplierModal,
        setRelationLoading: setRelationLoading,
        onSaveClick: saveSupplier,
        fields: ProductSupplierFormFields(),
        field: {
          onChange: onChangeSupplier
        },
        invalidFields: supplierInvalidFields,
        relation_id: {
          label: 'relation_id',
          options: [],
          selected: [],
          onChange: onChangeSupplierSingleSelect,
          clearable: Boolean(true),
          searchable: Boolean(true),
          placeholder: relationLoading ? 'Loading relations...' : 'Select...',
          disabled: relationLoading,
          loading: relationLoading
        },
        eans: {
          label: 'eans',
          options: [],
          selected: [],
          onChange: onSupplierEanChange,
          clearable: Boolean(true),
          searchable: Boolean(true)
        },
      },
      selected: checkedList
    }
  };

  const addCard = {
    size: 12,
    title: navTitle,
    data: <ProductForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    dispatch(setActiveTab('main'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    getDataFromApi()
      .finally(() => {
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, []);

  async function getDataFromApi() {
    return new Promise(async (resolve) => {
      if (!journalCategories || journalCategories?.length <= 0) {
        await GetAllCategoriesWithType({ type: 'JOURNAL', token: token, admin_id: admin_id, location_id: location_id })
          .then(async (response) => { dispatch(setJournalCategoriesAll(response)) })
          .catch(error => { HandleError({ error: error }) });
      }

      if (!productpageCategories || productpageCategories?.length <= 0) {
        await GetAllCategoriesWithType({ type: 'PRODUCTPAGE', token: token, admin_id: admin_id, location_id: location_id })
          .then(async (response) => { dispatch(setProductCategoriesAll(response)) })
          .catch(error => { HandleError({ error: error }) });
      }

      if (!vats_all || vats_all?.length <= 0) {
        await GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
          .then(async (response) => { dispatch(setVatsAll(response)) })
          .catch(error => { HandleError({ error: error }) });
      }

      if (scopes?.sortcategories) {
        await GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setPrintersAll(response))
          })
          .catch(error => { HandleError({ error: error }) });

        await GetAllCategories({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setSortCategoriesAll(response))
          })
          .catch(error => { HandleError({ error: error }) });

        await GetAllScreens({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setScreensAll(response))
          })
          .catch(error => { HandleError({ error: error }) });
      }

      await GetRelationsByTypeAll({ token: token, admin_id: admin_id, type: 'SUPPLIER' })
        .then(response => {
          dispatch(setRelationsAll(response));
        })
        .catch(error => {
          HandleError({ error: error })
        })
        .finally(() => {
          setSuppliers(product?.suppliers || []);
          setRelationLoading(false);
        })

      resolve(true)
    })
  }

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};
export default ProductAdd;