import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import useHandleError from "../../customhooks/useHandleError";
import { useTranslation } from 'react-i18next';
import GetDiscountsProductSelectionsList from "../../actablueAPI/discount/GetDiscountsProductSelectionsList";
import { getMixMatches, getMixMatchPage, getMixMatchPageSize, setMixMatches } from "../../redux/dataSlices/discountSlice";
import MixMatchTable from "../../components/mixmatch/mixMatchTable";

const MixMatch = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const mixMatches = useSelector(getMixMatches);
  const page = useSelector(getMixMatchPage);
  const size = useSelector(getMixMatchPageSize);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();
  const navTitle = "Discount"

  const mixMatchCard = {
    size: 12,
    title: "Mix Match",
    data: location_id ? <MixMatchTable data={mixMatches} /> : translate('Please select a location'),
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [navTitle]);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))
      GetDiscountsProductSelectionsList({ token: token, admin_id: admin_id, location_id: location_id, page: page, size: size })
        .then(response => {
          dispatch(setMixMatches(response?.data))
        })
        .catch(error => {
          HandleError({ error: error })
        })
        .finally(() => {
          dispatch(setLoading(false));
        })
    }

    // eslint-disable-next-line
  }, [, admin_id, location_id, page, size]);

  return (
    <>
      {scopes.mixmatch &&
        <BasicCard {...mixMatchCard} />
      }
    </>
  )
};
export default MixMatch;

