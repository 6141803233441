import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getRole } from '../../redux/dataSlices/tokenSlice';

import '../css/modal.css'
import JsonHighlighter from '../serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { getHashAdministrationsAll } from '../../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../../redux/dataSlices/locationSlice';
import { getDiscountProductLimit } from '../../redux/dataSlices/discountSlice';
import { DiscountLimitCalculations, DiscountLimitTypes } from '../../selectfieldchoices/discountselect.mjs';


export default function DiscountProductLimitModal(props) {
  const object = useSelector(getDiscountProductLimit);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Discount Product Limit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="discount_limit">{translate('Discount Limit')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Administration')}: {hash_admins_all[object.administration_id]?.name}</p>
                      <p>{translate('Location')}: {hash_locations_all[object.location_id]?.name}</p>
                      <p>{translate('Product')}: {object.product_id?.description}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Creation')}: {new Date(object?.creation).toLocaleString()}</p>
                      <p>{translate('Modified')}: {new Date(object?.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="discount_limit">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Type')}: {object?.discount_limit?.type ? DiscountLimitTypes.find(type => type.id === object?.discount_limit?.type)?.name : "Unknown"}</p>
                      <p>{translate('Calculation')}: {object?.discount_limit?.calculation ? DiscountLimitCalculations.find(calculation => calculation.id === object?.discount_limit?.calculation)?.name : "Unknown"}</p>
                      <p>{translate('Active')}: {object?.discount_limit?.active ?
                        <i className="bi bi-check-circle true" />
                        :
                        <i className="bi bi-x-square false" />
                      }
                      </p>
                      <p>{translate('Value')}: {object?.discount_limit?.value}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>id: {object?.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>admin_id: {object?.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>location_id: {object?.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}