import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import '../css/productTable.css';
import PaginationAndSizeComponent from '../paginationAndSize';
import { DiscountSettingsCalculations, DiscountSettingsTypes } from '../../selectfieldchoices/discountselect.mjs';
import DiscountProductButtons from '../../portal/discounts/discountProductButtons';
import DiscountProductModal from './discountProductModal';

export default function DiscountProductsTable({ data }) {
  const discountProducts = data?.items || data;
  const pages = data?.pages || 1
  const page = data?.page || 1
  const { t: translate } = useTranslation();

  const buttons = DiscountProductButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if (data) {
    for (const product of Object.values(discountProducts)) {
      rows.push(
        <tr className='product-row' key={product.id}>
          <td>{product?.discount_settings?.description ?? 'Unknown'}</td>
          <td>{product?.discount_settings?.type ? DiscountSettingsTypes.find(type => type.id === product?.discount_settings?.type)?.name : "Unknown"}</td>
          <td>{product?.discount_settings?.calculation ? DiscountSettingsCalculations.find(calculation => calculation.id === product?.discount_settings?.calculation)?.name : "Unknown"}</td>
          <td className='text-center'>
            <div className='table-icons'>
              {product?.discount_settings?.active ?
                <i className="bi bi-check-circle true" />
                :
                <i className="bi bi-x-square false" />
              }
            </div>
          </td>
          <td>{product?.discount_settings?.start ? new Date(product?.discount_settings?.start).toLocaleString() : "Not set"}</td>
          <td>{product?.discount_settings?.end ? new Date(product?.discount_settings?.end).toLocaleString() : "Not set"}</td>
          <td>
            <p>
              {translate('Created')}: {new Date(product?.creation).toLocaleString()}
            </p>
            <p>
              {translate('Modified')}: {new Date(product?.modification).toLocaleString()}
            </p>
          </td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(product) }} id={product?.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(product) }} id={product?.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Calculation')}</th>
            <th className='text-center'>{translate('Active')}</th>
            <th>
              {translate('Start')}
            </th>
            <th>
              {translate('End')}
            </th>
            <th>
              {translate('Date')}
            </th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <DiscountProductModal modal={modalview.modal} />
    </>
  );
}