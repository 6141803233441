export const DiscountLimitTypes = [
    { 'id': 'percentage', 'name': 'Percentage' },
    { 'id': 'amount', 'name': 'Amount' },
    { 'id': 'quantity', 'name': 'Quantity' },
]

export const DiscountLimitCalculations = [
    { 'id': 'sold_product', 'name': 'Sold Product' },
    { 'id': 'product_id', 'name': 'Product ID' },
    { 'id': 'limit_scope', 'name': 'Limit Scope' },
]

export const DiscountSettingsTypes = [
    { 'id': 'percentage', 'name': 'Percentage' },
    { 'id': 'amount', 'name': 'Amount' },
]

export const DiscountSettingsCalculations = [
    { 'id': 'always', 'name': 'Always' },
    { 'id': 'threshold', 'name': 'Threshold' },
    { 'id': 'modulo', 'name': 'Modulo' },
]