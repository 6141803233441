import axios from "axios";

const PostDiscountsProductSelection = ({ token, admin_id, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/discounts/product_selections`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        "administration": admin_id
      },
      data: data
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default PostDiscountsProductSelection