import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import useHandleError from "../../customhooks/useHandleError";
import { useTranslation } from 'react-i18next';
import { getDiscountCategoryLimitPage, getDiscountCategoryLimitPageSize, getDiscountCategoryLimits, getDiscountCategoryPage, getDiscountCategoryPageSize, getDiscountCategories, setDiscountCategoryLimits, setDiscountCategories } from "../../redux/dataSlices/discountSlice";
import GetDiscountsCategoriesList from "../../actablueAPI/discount/GetDiscountsCategoriesList";
import DiscountCategoriesTable from "../../components/discountCategories/discountCategoriesTable";
import GetDiscountsCategoryLimitsList from "../../actablueAPI/discount/GetDiscountsCategoryLimitsList";
import DiscountCategoryLimitsTable from "../../components/discountCategories/discountCategoryLimitsTable";

const DiscountCategories = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const discountCategories = useSelector(getDiscountCategories);
  const discountCategoryLimits = useSelector(getDiscountCategoryLimits);
  const page = useSelector(getDiscountCategoryPage);
  const size = useSelector(getDiscountCategoryPageSize);
  const limitsPage = useSelector(getDiscountCategoryLimitPage);
  const limitsSize = useSelector(getDiscountCategoryLimitPageSize);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();
  const navTitle = "Discount"

  const discountCategoriesCard = {
    size: 12,
    title: "Category Discounts",
    data: location_id ? <DiscountCategoriesTable data={discountCategories} /> : translate('Please select a location'),
  };

  const discountCategoryLimitsCard = {
    size: 12,
    title: "Category Discount Limits",
    data: location_id ? <DiscountCategoryLimitsTable data={discountCategoryLimits} /> : translate('Please select a location'),
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [navTitle]);

  useEffect(() => {
    if (location_id) {

      const getDiscountCategories = async () => {
        try {
          const categoryResponse = await GetDiscountsCategoriesList({
            token,
            admin_id,
            location_id,
            page,
            size,
          });
          dispatch(setDiscountCategories(categoryResponse?.data));
        } catch (error) {
          HandleError({ error });
        }
      };

      const getDiscountCategoryLimits = async () => {
        try {
          const categoryLimitsResponse = await GetDiscountsCategoryLimitsList({
            token,
            admin_id,
            location_id,
            page: limitsPage,
            size: limitsSize,
          });
          dispatch(setDiscountCategoryLimits(categoryLimitsResponse?.data));
        } catch (error) {
          HandleError({ error });
        }
      };

      dispatch(setLoading(true))
      Promise.all([getDiscountCategories(), getDiscountCategoryLimits()])
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, page, size, limitsPage, limitsSize]);

  return (
    <>
      {scopes.discountcategories &&
        <>
          <BasicCard {...discountCategoriesCard} />
          <BasicCard {...discountCategoryLimitsCard} />
        </>
      }
    </>
  )
};
export default DiscountCategories;

