import { createSlice } from "@reduxjs/toolkit";

export const discountSlice = createSlice({
  name: "discount",
  initialState: {
    mixMatchPage: 1,
    mixMatchPageSize: 10,
    discountRelationPage: 1,
    discountRelationPageSize: 10,
    discountRelationLimitsPage: 1,
    discountRelationLimitsPageSize: 10,
    discountRelationCategoryPage: 1,
    discountRelationCategoryPageSize: 10,
    discountRelationProductPage: 1,
    discountRelationProductPageSize: 10,
    discountCategoryPage: 1,
    discountCategoryPageSize: 10,
    discountCategoryLimitsPage: 1,
    discountCategoryLimitsPageSize: 10,
    discountProductPage: 1,
    discountProductPageSize: 10,
    discountProductLimitsPage: 1,
    discountProductLimitsPageSize: 10,
    hashMixMatchesAll: {},
    hashDiscountRelationsAll: {},
    hashDiscountRelationLimitsAll: {},
    hashDiscountRelationCategoriesAll: {},
    hashDiscountRelationProductsAll: {},
    hashDiscountCategoriesAll: {},
    hashDiscountCategoryLimitsAll: {},
    hashDiscountProductsAll: {},
    hashDiscountProductLimitsAll: {}
  },
  reducers: {
    setMixMatchId: (state, action) => {
      state.mixmatch_id = action.payload;
    },
    setMixMatch: (state, action) => {
      state.mixmatch = action.payload;
    },
    setMixMatches: (state, action) => {
      state.mixmatches = action.payload
    },
    setMixMatchesAll: (state, action) => {
      state.mixmatches_all = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(mixmatch => {
          state.hashMixMatchesAll[mixmatch.id] = mixmatch
        });
      } else {
        action?.payload?.forEach(mixmatch => {
          state.hashMixMatchesAll[mixmatch.id] = mixmatch
        });
      }
    },
    setMixMatchModalShow: (state, action) => {
      state.mixMatchModalShow = action.payload
    },
    setMixMatchPagination: (state, action) => {
      state.mixMatchPage = action.payload
    },
    setMixMatchPageSize: (state, action) => {
      state.mixMatchPageSize = action.payload
    },
    setDiscountRelationId: (state, action) => {
      state.discountRelationId = action.payload;
    },
    setDiscountRelation: (state, action) => {
      state.discountRelation = action.payload;
    },
    setDiscountRelations: (state, action) => {
      state.discountRelations = action.payload
    },
    setDiscountRelationsAll: (state, action) => {
      state.discountRelationsAll = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(relation => {
          state.hashDiscountRelationsAll[relation.id] = relation
        });
      } else {
        action?.payload?.forEach(relation => {
          state.hashDiscountRelationsAll[relation.id] = relation
        });
      }
    },
    setDiscountRelationModalShow: (state, action) => {
      state.discountRelationModalShow = action.payload
    },
    setDiscountRelationPagination: (state, action) => {
      state.discountRelationPage = action.payload
    },
    setDiscountRelationPageSize: (state, action) => {
      state.discountRelationPageSize = action.payload
    },
    setDiscountRelationLimitId: (state, action) => {
      state.discountRelationLimitId = action.payload;
    },
    setDiscountRelationLimit: (state, action) => {
      state.discountRelationLimit = action.payload;
    },
    setDiscountRelationLimits: (state, action) => {
      state.discountRelationLimits = action.payload
    },
    setDiscountRelationLimitsAll: (state, action) => {
      state.discountRelationLimitsAll = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(relation => {
          state.hashDiscountRelationLimitsAll[relation.id] = relation
        });
      } else {
        action?.payload?.forEach(relation => {
          state.hashDiscountRelationLimitsAll[relation.id] = relation
        });
      }
    },
    setDiscountRelationLimitModalShow: (state, action) => {
      state.discountRelationLimitsModalShow = action.payload
    },
    setDiscountRelationLimitPagination: (state, action) => {
      state.discountRelationLimitsPage = action.payload
    },
    setDiscountRelationLimitPageSize: (state, action) => {
      state.discountRelationLimitsPageSize = action.payload
    },
    setDiscountProductId: (state, action) => {
      state.discountProductId = action.payload;
    },
    setDiscountProduct: (state, action) => {
      state.discountProduct = action.payload;
    },
    setDiscountProducts: (state, action) => {
      state.discountProducts = action.payload
    },
    setDiscountProductsAll: (state, action) => {
      state.discountProductsAll = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(product => {
          state.hashDiscountProductsAll[product.id] = product
        });
      } else {
        action?.payload?.forEach(product => {
          state.hashDiscountProductsAll[product.id] = product
        });
      }
    },
    setDiscountProductModalShow: (state, action) => {
      state.discountProductModalShow = action.payload
    },
    setDiscountProductPagination: (state, action) => {
      state.discountProductPage = action.payload
    },
    setDiscountProductPageSize: (state, action) => {
      state.discountProductPageSize = action.payload
    },
    setDiscountProductLimitId: (state, action) => {
      state.discountProductLimitId = action.payload;
    },
    setDiscountProductLimit: (state, action) => {
      state.discountProductLimit = action.payload;
    },
    setDiscountProductLimits: (state, action) => {
      state.discountProductLimits = action.payload
    },
    setDiscountProductLimitsAll: (state, action) => {
      state.discountProductLimitsAll = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(product => {
          state.hashDiscountProductLimitsAll[product.id] = product
        });
      } else {
        action?.payload?.forEach(product => {
          state.hashDiscountProductLimitsAll[product.id] = product
        });
      }
    },
    setDiscountProductLimitModalShow: (state, action) => {
      state.discountProductLimitsModalShow = action.payload
    },
    setDiscountProductLimitPagination: (state, action) => {
      state.discountProductLimitsPage = action.payload
    },
    setDiscountProductLimitPageSize: (state, action) => {
      state.discountProductLimitsPageSize = action.payload
    },
    setDiscountCategoryId: (state, action) => {
      state.discountCategoryId = action.payload;
    },
    setDiscountCategory: (state, action) => {
      state.discountCategory = action.payload;
    },
    setDiscountCategories: (state, action) => {
      state.discountCategories = action.payload
    },
    setDiscountCategoriesAll: (state, action) => {
      state.discountCategoriesAll = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(category => {
          state.hashDiscountCategoriesAll[category.id] = category
        });
      } else {
        action?.payload?.forEach(category => {
          state.hashDiscountCategoriesAll[category.id] = category
        });
      }
    },
    setDiscountCategoryModalShow: (state, action) => {
      state.discountCategoryModalShow = action.payload
    },
    setDiscountCategoryPagination: (state, action) => {
      state.discountCategoryPage = action.payload
    },
    setDiscountCategoryPageSize: (state, action) => {
      state.discountCategoryPageSize = action.payload
    },
    setDiscountCategoryLimitId: (state, action) => {
      state.discountCategoryLimitId = action.payload;
    },
    setDiscountCategoryLimit: (state, action) => {
      state.discountCategoryLimit = action.payload;
    },
    setDiscountCategoryLimits: (state, action) => {
      state.discountCategoryLimits = action.payload
    },
    setDiscountCategoryLimitsAll: (state, action) => {
      state.discountCategoryLimitsAll = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(category => {
          state.hashDiscountCategoryLimitsAll[category.id] = category
        });
      } else {
        action?.payload?.forEach(category => {
          state.hashDiscountCategoryLimitsAll[category.id] = category
        });
      }
    },
    setDiscountCategoryLimitModalShow: (state, action) => {
      state.discountCategoryLimitsModalShow = action.payload
    },
    setDiscountCategoryLimitPagination: (state, action) => {
      state.discountCategoryLimitsPage = action.payload
    },
    setDiscountCategoryLimitPageSize: (state, action) => {
      state.discountCategoryLimitsPageSize = action.payload
    },
    setDiscountRelationCategoryId: (state, action) => {
      state.discountRelationCategoryId = action.payload;
    },
    setDiscountRelationCategory: (state, action) => {
      state.discountRelationCategory = action.payload;
    },
    setDiscountRelationCategories: (state, action) => {
      state.discountRelationCategories = action.payload
    },
    setDiscountRelationCategoriesAll: (state, action) => {
      state.discountRelationCategoriesAll = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(relation => {
          state.hashDiscountRelationCategoriesAll[relation.id] = relation
        });
      } else {
        action?.payload?.forEach(relation => {
          state.hashDiscountRelationCategoriesAll[relation.id] = relation
        });
      }
    },
    setDiscountRelationCategoryModalShow: (state, action) => {
      state.discountRelationCategoryModalShow = action.payload
    },
    setDiscountRelationCategoryPagination: (state, action) => {
      state.discountRelationCategoryPage = action.payload
    },
    setDiscountRelationCategoryPageSize: (state, action) => {
      state.discountRelationCategoryPageSize = action.payload
    },
    setDiscountRelationProductId: (state, action) => {
      state.discountRelationProductId = action.payload;
    },
    setDiscountRelationProduct: (state, action) => {
      state.discountRelationProduct = action.payload;
    },
    setDiscountRelationProducts: (state, action) => {
      state.discountRelationProducts = action.payload
    },
    setDiscountRelationProductsAll: (state, action) => {
      state.discountRelationProductsAll = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(relation => {
          state.hashDiscountRelationProductsAll[relation.id] = relation
        });
      } else {
        action?.payload?.forEach(relation => {
          state.hashDiscountRelationProductsAll[relation.id] = relation
        });
      }
    },
    setDiscountRelationProductModalShow: (state, action) => {
      state.discountRelationProductModalShow = action.payload
    },
    setDiscountRelationProductPagination: (state, action) => {
      state.discountRelationProductPage = action.payload
    },
    setDiscountRelationProductPageSize: (state, action) => {
      state.discountRelationProductPageSize = action.payload
    },
  }
});

export const {
  setMixMatch, setMixMatchId, setMixMatchModalShow, setMixMatchPageSize, setMixMatchPagination, setMixMatches, setMixMatchesAll,
  setDiscountRelation, setDiscountRelationId, setDiscountRelationLimit, setDiscountRelationLimitId, setDiscountRelationLimitModalShow, setDiscountRelationLimitPageSize,
  setDiscountRelationLimitPagination, setDiscountRelationLimits, setDiscountRelationLimitsAll, setDiscountRelationModalShow, setDiscountRelationPageSize, setDiscountRelationPagination,
  setDiscountRelations, setDiscountRelationsAll, setDiscountCategories, setDiscountCategoriesAll, setDiscountCategory, setDiscountCategoryId, setDiscountCategoryLimit, setDiscountCategoryLimitId,
  setDiscountCategoryLimitModalShow, setDiscountCategoryLimitPageSize, setDiscountCategoryLimitPagination, setDiscountCategoryLimits, setDiscountCategoryLimitsAll, setDiscountCategoryModalShow,
  setDiscountCategoryPageSize, setDiscountCategoryPagination, setDiscountProduct, setDiscountProductId, setDiscountProductLimit, setDiscountProductLimitId, setDiscountProductLimitModalShow,
  setDiscountProductLimitPageSize, setDiscountProductLimitPagination, setDiscountProductLimits, setDiscountProductLimitsAll, setDiscountProductModalShow, setDiscountProductPageSize, setDiscountProductPagination,
  setDiscountProducts, setDiscountProductsAll,
  setDiscountRelationCategories, setDiscountRelationCategoriesAll, setDiscountRelationCategory, setDiscountRelationCategoryId, setDiscountRelationCategoryModalShow,
  setDiscountRelationCategoryPageSize, setDiscountRelationCategoryPagination, setDiscountRelationProduct, setDiscountRelationProductId, setDiscountRelationProductModalShow,
  setDiscountRelationProductPageSize, setDiscountRelationProductPagination, setDiscountRelationProducts, setDiscountRelationProductsAll
} = discountSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getMixMatchId = (state) => state.discount.mixmatch_id
export const getMixMatch = (state) => state.discount.mixmatch
export const getMixMatches = (state) => state.discount.mixmatches
export const getMixMatchesAll = (state) => state.discount.mixmatches_all
export const getHashMixMatchesAll = (state) => state.discount.hashMixMatchesAll
export const getMixMatchModalShow = (state) => state.discount.mixMatchModalShow
export const getMixMatchPage = (state) => state.discount.mixMatchPage
export const getMixMatchPageSize = (state) => state.discount.mixMatchPageSize

export const getDiscountRelationId = (state) => state.discount.discountRelationId
export const getDiscountRelation = (state) => state.discount.discountRelation
export const getDiscountRelations = (state) => state.discount.discountRelations
export const getDiscountRelationsAll = (state) => state.discount.discountRelationsAll
export const getHashDiscountRelationsAll = (state) => state.discount.hashDiscountRelationsAll
export const getDiscountRelationModalShow = (state) => state.discount.discountRelationModalShow
export const getDiscountRelationPage = (state) => state.discount.discountRelationPage
export const getDiscountRelationPageSize = (state) => state.discount.discountRelationPageSize

export const getDiscountRelationLimitId = (state) => state.discount.discountRelationLimitId
export const getDiscountRelationLimit = (state) => state.discount.discountRelationLimit
export const getDiscountRelationLimits = (state) => state.discount.discountRelationLimits
export const getDiscountRelationLimitsAll = (state) => state.discount.discountRelationLimitsAll
export const getHashDiscountRelationLimitsAll = (state) => state.discount.hashDiscountRelationLimitsAll
export const getDiscountRelationLimitModalShow = (state) => state.discount.discountRelationLimitsModalShow
export const getDiscountRelationLimitPage = (state) => state.discount.discountRelationLimitsPage
export const getDiscountRelationLimitPageSize = (state) => state.discount.discountRelationLimitsPageSize

export const getDiscountProductId = (state) => state.discount.discountProductId
export const getDiscountProduct = (state) => state.discount.discountProduct
export const getDiscountProducts = (state) => state.discount.discountProducts
export const getDiscountProductsAll = (state) => state.discount.discountProductsAll
export const getHashDiscountProductsAll = (state) => state.discount.hashDiscountProductsAll
export const getDiscountProductModalShow = (state) => state.discount.discountProductModalShow
export const getDiscountProductPage = (state) => state.discount.discountProductPage
export const getDiscountProductPageSize = (state) => state.discount.discountProductPageSize

export const getDiscountProductLimitId = (state) => state.discount.discountProductLimitId
export const getDiscountProductLimit = (state) => state.discount.discountProductLimit
export const getDiscountProductLimits = (state) => state.discount.discountProductLimits
export const getDiscountProductLimitsAll = (state) => state.discount.discountProductLimitsAll
export const getHashDiscountProductLimitsAll = (state) => state.discount.hashDiscountProductLimitsAll
export const getDiscountProductLimitModalShow = (state) => state.discount.discountProductLimitsModalShow
export const getDiscountProductLimitPage = (state) => state.discount.discountProductLimitsPage
export const getDiscountProductLimitPageSize = (state) => state.discount.discountProductLimitsPageSize

export const getDiscountCategoryId = (state) => state.discount.discountCategoryId
export const getDiscountCategory = (state) => state.discount.discountCategory
export const getDiscountCategories = (state) => state.discount.discountCategories
export const getDiscountCategoriesAll = (state) => state.discount.discountCategoriesAll
export const getHashDiscountCategoriesAll = (state) => state.discount.hashDiscountCategoriesAll
export const getDiscountCategoryModalShow = (state) => state.discount.discountCategoryModalShow
export const getDiscountCategoryPage = (state) => state.discount.discountCategoryPage
export const getDiscountCategoryPageSize = (state) => state.discount.discountCategoryPageSize

export const getDiscountCategoryLimitId = (state) => state.discount.discountCategoryLimitId
export const getDiscountCategoryLimit = (state) => state.discount.discountCategoryLimit
export const getDiscountCategoryLimits = (state) => state.discount.discountCategoryLimits
export const getDiscountCategoryLimitsAll = (state) => state.discount.discountCategoryLimitsAll
export const getHashDiscountCategoryLimitsAll = (state) => state.discount.hashDiscountCategoryLimitsAll
export const getDiscountCategoryLimitModalShow = (state) => state.discount.discountCategoryLimitsModalShow
export const getDiscountCategoryLimitPage = (state) => state.discount.discountCategoryLimitsPage
export const getDiscountCategoryLimitPageSize = (state) => state.discount.discountCategoryLimitsPageSize

export const getDiscountRelationCategoryId = (state) => state.discount.discountRelationCategoryId
export const getDiscountRelationCategory = (state) => state.discount.discountRelationCategory
export const getDiscountRelationCategories = (state) => state.discount.discountRelationCategories
export const getDiscountRelationCategoriesAll = (state) => state.discount.discountRelationCategoriesAll
export const getHashDiscountRelationCategoriesAll = (state) => state.discount.hashDiscountRelationCategoriesAll
export const getDiscountRelationCategoryModalShow = (state) => state.discount.discountRelationCategoryModalShow
export const getDiscountRelationCategoryPage = (state) => state.discount.discountRelationCategoryPage
export const getDiscountRelationCategoryPageSize = (state) => state.discount.discountRelationCategoryPageSize

export const getDiscountRelationProductId = (state) => state.discount.discountRelationProductId
export const getDiscountRelationProduct = (state) => state.discount.discountRelationProduct
export const getDiscountRelationProducts = (state) => state.discount.discountRelationProducts
export const getDiscountRelationProductsAll = (state) => state.discount.discountRelationProductsAll
export const getHashDiscountRelationProductsAll = (state) => state.discount.hashDiscountRelationProductsAll
export const getDiscountRelationProductModalShow = (state) => state.discount.discountRelationProductModalShow
export const getDiscountRelationProductPage = (state) => state.discount.discountRelationProductPage
export const getDiscountRelationProductPageSize = (state) => state.discount.discountRelationProductPageSize

export default discountSlice.reducer;