import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import '../css/productTable.css';
import PaginationAndSizeComponent from '../paginationAndSize';
import { DiscountLimitCalculations, DiscountLimitTypes } from '../../selectfieldchoices/discountselect.mjs';
import DiscountProductLimitButtons from '../../portal/discounts/discountProductLimitButtons';
import DiscountProductLimitModal from './discountProductLimitModal';

export default function DiscountProductLimitsTable({ data }) {
  const discountProductLimits = data?.items || data;
  const pages = data?.pages || 1
  const page = data?.page || 1
  const { t: translate } = useTranslation();

  const buttons = DiscountProductLimitButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if (data) {
    for (const productLimit of Object.values(discountProductLimits)) {
      rows.push(
        <tr className='product-row' key={productLimit.id}>
          <td>{productLimit?.product_id?.description ?? 'Unknown'}</td>
          <td>{productLimit?.discount_limit?.type ? DiscountLimitTypes.find(type => type.id === productLimit?.discount_limit?.type)?.name : "Unknown"}</td>
          <td>{productLimit?.discount_limit?.calculation ? DiscountLimitCalculations.find(calculation => calculation.id === productLimit?.discount_limit?.calculation)?.name : "Unknown"}</td>
          <td className='text-center'>
            <div className='table-icons'>
              {productLimit?.discount_limit?.active ?
                <i className="bi bi-check-circle true" />
                :
                <i className="bi bi-x-square false" />
              }
            </div>
          </td>
          <td>
            <p>
              {translate('Created')}: {new Date(productLimit?.creation).toLocaleString()}
            </p>
            <p>
              {translate('Modified')}: {new Date(productLimit?.modification).toLocaleString()}
            </p>
          </td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(productLimit) }} id={productLimit?.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(productLimit) }} id={productLimit?.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table width='100%'>
        <thead>
          <tr>
            <th>{translate('Product Name')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Calculation')}</th>
            <th className='text-center'>{translate('Active')}</th>
            <th>
              {translate('Date')}
            </th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <DiscountProductLimitModal modal={modalview.modal} />
    </>
  );
}