import { configureStore, combineReducers } from '@reduxjs/toolkit'
import errorSlice from './dataSlices/errorSlice'
import tokenSlice from './dataSlices/tokenSlice'
import opticonSlice from './dataSlices/opticonSlice'
import warehouseSlice from './dataSlices/warehouseSlice'
import ccvshopSlice from './dataSlices/ccvshopSlice'
import snelstartSlice from './dataSlices/snelstartSlice'
import deviceSlice from './dataSlices/deviceSlice'
import accountingSlice from './dataSlices/accountingSlice'
import contactSlice from './dataSlices/contactSlice'
import relationSlice from './dataSlices/relationSlice'
import attributeSlice from './dataSlices/attributeSlice'
import vatSlice from './dataSlices/vatSlice'
import productSlice from './dataSlices/productSlice'
import reservationSlice from './dataSlices/reservationSlice'
import orderSlice from './dataSlices/orderSlice'
import invoiceSlice from './dataSlices/invoiceSlice'
import categorySlice from './dataSlices/categorySlice'
import productsupplierSlice from './dataSlices/productsupplierSlice'
import locationSlice from './dataSlices/locationSlice'
import administrationSlice from './dataSlices/administrationSlice'
import settingsSlice from './dataSlices/settingsSlice'
import templateSlice from './dataSlices/templateSlice'
import registrationSlice from './dataSlices/registrationSlice'
import selectAdminSlice from './dataSlices/selectAdminSlice'
import selectLocationSlice from './dataSlices/selectLocationSlice'
import navbartitleSlice from './dataSlices/navbartitleSlice'
import messageSlice from './dataSlices/messageSlice'
import loadingSlice from './dataSlices/loadingSlice'
import scopesSlice from './dataSlices/scopesSlice'
import sidebarSlice from './dataSlices/sidebarSlice'
import subscriptionSlice from './dataSlices/subscriptionSlice'
import userSlice from './dataSlices/userSlice'
import printerSlice from './dataSlices/printerSlice'
import transactionSlice from './dataSlices/transactionSlice'
import reportingSlice from './dataSlices/reportingSlice'
import guestcheckSlice from './dataSlices/guestcheckSlice'
import importSlice from './dataSlices/importSlice'
import exportSlice from './dataSlices/exportSlice'
import employeeSlice from './dataSlices/employeeSlice'
import analyseSlice from './dataSlices/analyseSlice'
import notificationSlice from './dataSlices/notificationSlice'
import onlinepaymentSlice from './dataSlices/onlinepaymentSlice'
import keycloakSlice from './dataSlices/keycloakSlice'
import cashcountSlice from './dataSlices/cashcountSlice'
import formTabSlice from './dataSlices/formTabSlice'
import matchingSlice from './dataSlices/matchingSlice'
import navbarSlice from './dataSlices/navbarSlice'
import qrorderingSlice from './dataSlices/qrorderingSlice'
import loyaltySlice from './dataSlices/loyaltySlice'
import reportingDateSlice from './dataSlices/reportingDateSlice'
import kitchenmanagerSlice from './dataSlices/kitchenmanagerSlice'
import narrowcastingSlice from './dataSlices/narrowcastingSlice'
import loggedInUserSlice from './dataSlices/loggedInUserSlice'
import planningSlice from './dataSlices/planningSlice'
import eJournalSlice from './dataSlices/eJournalSlice'
import discountSlice from './dataSlices/discountSlice'

const combineClearLocationReducer = combineReducers({
  opticon: opticonSlice,
  warehouse: warehouseSlice,
  ccvshop: ccvshopSlice,
  snelstart: snelstartSlice,
  device: deviceSlice,
  accounting: accountingSlice,
  contact: contactSlice,
  relation: relationSlice,
  attribute: attributeSlice,
  vat: vatSlice,
  product: productSlice,
  reservation: reservationSlice,
  order: orderSlice,
  invoice: invoiceSlice,
  category: categorySlice,
  productsupplier: productsupplierSlice,
  settings: settingsSlice,
  template: templateSlice,
  registration: registrationSlice,
  message: messageSlice,
  loading: loadingSlice,
  subscription: subscriptionSlice,
  user: userSlice,
  printer: printerSlice,
  transaction: transactionSlice,
  reporting: reportingSlice,
  guestcheck: guestcheckSlice,
  import: importSlice,
  export: exportSlice,
  analyse: analyseSlice,
  notification: notificationSlice,
  onlinepayment: onlinepaymentSlice,
  keycloak: keycloakSlice,
  selectLocation: selectLocationSlice,
  cashcount: cashcountSlice,
  formtab: formTabSlice,
  matching: matchingSlice,
  qrordering: qrorderingSlice,
  loyalty: loyaltySlice,
  kitchenmanager: kitchenmanagerSlice,
  narrowcasting: narrowcastingSlice,
  planning: planningSlice,
  eJournal: eJournalSlice,
  discount: discountSlice
})

const combineClearAdminReducer = combineReducers({
  selectAdmin: selectAdminSlice,
  scopes: scopesSlice,
  sidebar: sidebarSlice,
  employee: employeeSlice
})

const combinedNotClearedReducer = combineReducers({
  error: errorSlice,
  token: tokenSlice,
  location: locationSlice,
  administration: administrationSlice,
  navbartitle: navbartitleSlice,
  message: messageSlice,
  navbar: navbarSlice,
  reportingDate: reportingDateSlice,
  loggedInUser: loggedInUserSlice
})

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'loggedInUser/clearReduxAll':
      return {...combineClearLocationReducer(undefined, action), ...combineClearAdminReducer(undefined, action), ...combinedNotClearedReducer(undefined, action)};  
    case 'administration/clearReduxAdministration':
      return {...combineClearLocationReducer(undefined, action), ...combineClearAdminReducer(undefined, action), ...combinedNotClearedReducer(state, action)};  
    case 'location/clearReduxLocation':
      return {...combineClearLocationReducer(undefined, action), ...combineClearAdminReducer(state, action), ...combinedNotClearedReducer(state, action)};  
    default:
      return {...combineClearLocationReducer(state, action), ...combineClearAdminReducer(state, action), ...combinedNotClearedReducer(state, action)};
  }
};

export default configureStore({
  reducer: rootReducer
})