import { useSelector } from "react-redux"
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice"
import PostProductSearch from "../../actablueAPI/products/PostProductsSearch"
import { useRef, useState } from "react"
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice"
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice"
import PostRelationSearch from "../../actablueAPI/relations/PostRelationsSearch"

export const DiscountSettingsFormFields = () => {
  const discountSettingsFormFields = [
    { type: 'text', name: 'Description', id: 'description', prefix: 'discount_settings', required: true },
    { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', prefix: 'discount_settings', required: true },
    { type: 'select', name: 'Calculation', id: 'calculation', formdatafield: 'calculation', prefix: 'discount_settings', required: true },
    { type: 'checkbox', name: 'Active', id: 'active', prefix: 'discount_settings', },
    { type: 'datetime-local', name: 'Start', id: 'start', step: '1', prefix: 'discount_settings', required: false },
    { type: 'datetime-local', name: 'End', id: 'end', step: '1', prefix: 'discount_settings', required: false }
  ]

  return discountSettingsFormFields
}

export const DiscountValuesFormFields = () => {
  const discountSettingsFormFields = [
    { type: 'number', name: `required_quantity`, id: 'required_quantity', prefix: 'discount_values', required: false },
    { type: 'number', name: `value`, id: 'value', prefix: 'discount_values', required: false },
  ]

  return discountSettingsFormFields
}

export const DiscountLimitsFormFields = () => {
  const discountLimitsFormFields = [
    { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', prefix: 'discount_limits', required: false },
    { type: 'select', name: 'Calculation', id: 'calculation', formdatafield: 'calculation', prefix: 'discount_limits', required: false },
    { type: 'checkbox', name: 'Active', id: 'active', prefix: 'discount_limits' },
    { type: 'number', name: `Value`, id: 'value', prefix: 'discount_limits', required: false },
  ]

  return discountLimitsFormFields
}

export const DiscountLimitFormFields = () => {
  const discountLimitFormFields = [
    { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', prefix: 'discount_limit', required: true },
    { type: 'select', name: 'Calculation', id: 'calculation', formdatafield: 'calculation', prefix: 'discount_limit', required: true },
    { type: 'checkbox', name: 'Active', id: 'active', prefix: 'discount_limit' },
    { type: 'number', name: `Value`, id: 'value', prefix: 'discount_limit', required: true },
  ]

  return discountLimitFormFields
}

export const MixMatchFormFields = () => {
  const role = useSelector(getRole)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const [controller, setController] = useState();
  const signal = useRef();

  const asyncSelectLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'text': inputValue,
      'fuzziness': 2,
      'type': 'article',
      'fields': ['name', 'skuCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostProductSearch({ token: token, admin_id: admin_id, location_id: location_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };

  const mixMatchFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'async-multiselect', name: 'Products', id: 'product_ids', formdatafield: 'product_ids', required: true, loadOptions: asyncSelectLoadOptions },
    { type: 'object', name: `Discount Settings`, id: 'discount_settings', fields: DiscountSettingsFormFields() },
    { type: 'object', name: `Discount Values`, id: 'discount_values', fields: DiscountValuesFormFields() },
    { type: 'object', name: `Discount Limits`, id: 'discount_limits', fields: DiscountLimitsFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    mixMatchFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    mixMatchFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return mixMatchFormFields
}

export const DiscountRelationFormFields = () => {
  const role = useSelector(getRole)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const [controller, setController] = useState();
  const signal = useRef();

  const asyncSelectLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'search': inputValue,
      'fields': ['name', 'coc', 'iban', 'bic', 'place', 'street', 'email', 'vatNumber', 'zipCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostRelationSearch({ token: token, admin_id: admin_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };

  const discountRelationFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'async-select', name: 'Relation', id: 'relation_id', formdatafield: 'relation_id', required: true, loadOptions: asyncSelectLoadOptions },
    { type: 'object', name: `Discount Settings`, id: 'discount_settings', fields: DiscountSettingsFormFields() },
    { type: 'object', name: `Discount Values`, id: 'discount_values', fields: DiscountValuesFormFields() },
    { type: 'object', name: `Discount Limits`, id: 'discount_limits', fields: DiscountLimitsFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    discountRelationFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    discountRelationFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return discountRelationFormFields
}

export const DiscountRelationLimitFormFields = () => {
  const role = useSelector(getRole)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const [controller, setController] = useState();
  const signal = useRef();

  const asyncSelectLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'search': inputValue,
      'fields': ['name', 'coc', 'iban', 'bic', 'place', 'street', 'email', 'vatNumber', 'zipCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostRelationSearch({ token: token, admin_id: admin_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };

  const discountRelationLimitFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'async-select', name: 'Relation', id: 'relation_id', formdatafield: 'relation_id', required: true, loadOptions: asyncSelectLoadOptions },
    { type: 'object', name: `Discount Limit`, id: 'discount_limit', fields: DiscountLimitFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    discountRelationLimitFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    discountRelationLimitFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return discountRelationLimitFormFields
}

export const DiscountCategoryFormFields = () => {
  const role = useSelector(getRole);

  const discountCategoryFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'select', name: 'Category', id: 'category_id', formdatafield: 'category_id', required: true },
    { type: 'object', name: `Discount Settings`, id: 'discount_settings', fields: DiscountSettingsFormFields() },
    { type: 'object', name: `Discount Values`, id: 'discount_values', fields: DiscountValuesFormFields() },
    { type: 'object', name: `Discount Limits`, id: 'discount_limits', fields: DiscountLimitsFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    discountCategoryFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    discountCategoryFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return discountCategoryFormFields
}

export const DiscountCategoryLimitFormFields = () => {
  const role = useSelector(getRole);

  const discountCategoryLimitFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'select', name: 'Category', id: 'category_id', formdatafield: 'category_id', required: true },
    { type: 'object', name: `Discount Limit`, id: 'discount_limit', fields: DiscountLimitFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    discountCategoryLimitFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    discountCategoryLimitFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return discountCategoryLimitFormFields
}

export const DiscountProductFormFields = () => {
  const role = useSelector(getRole)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const [controller, setController] = useState();
  const signal = useRef();

  const asyncSelectLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'text': inputValue,
      'fuzziness': 2,
      'type': 'article',
      'fields': ['name', 'skuCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostProductSearch({ token: token, admin_id: admin_id, location_id: location_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };

  const discountProductFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'async-select', name: 'Product', id: 'product_id', formdatafield: 'product_id', required: true, loadOptions: asyncSelectLoadOptions },
    { type: 'object', name: `Discount Settings`, id: 'discount_settings', fields: DiscountSettingsFormFields() },
    { type: 'object', name: `Discount Values`, id: 'discount_values', fields: DiscountValuesFormFields() },
    { type: 'object', name: `Discount Limits`, id: 'discount_limits', fields: DiscountLimitsFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    discountProductFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    discountProductFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return discountProductFormFields
}

export const DiscountProductLimitFormFields = () => {
  const role = useSelector(getRole)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const [controller, setController] = useState();
  const signal = useRef();

  const asyncSelectLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'text': inputValue,
      'fuzziness': 2,
      'type': 'article',
      'fields': ['name', 'skuCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostProductSearch({ token: token, admin_id: admin_id, location_id: location_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };

  const discountProductLimitFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'async-select', name: 'Product', id: 'product_id', formdatafield: 'product_id', required: true, loadOptions: asyncSelectLoadOptions },
    { type: 'object', name: `Discount Limit`, id: 'discount_limit', fields: DiscountLimitFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    discountProductLimitFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    discountProductLimitFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return discountProductLimitFormFields
}

export const DiscountRelationCategoryFormFields = () => {
  const role = useSelector(getRole)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const [controller, setController] = useState();
  const signal = useRef();

  const asyncSelectLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'search': inputValue,
      'fields': ['name', 'coc', 'iban', 'bic', 'place', 'street', 'email', 'vatNumber', 'zipCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostRelationSearch({ token: token, admin_id: admin_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };

  const discountRelationCategoryFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'async-select', name: 'Relation', id: 'relation_id', formdatafield: 'relation_id', required: true, loadOptions: asyncSelectLoadOptions },
    { type: 'select', name: 'Category', id: 'category_id', formdatafield: 'category_id', required: true },
    { type: 'object', name: `Discount Settings`, id: 'discount_settings', fields: DiscountSettingsFormFields() },
    { type: 'object', name: `Discount Values`, id: 'discount_values', fields: DiscountValuesFormFields() },
    { type: 'object', name: `Discount Limits`, id: 'discount_limits', fields: DiscountLimitsFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    discountRelationCategoryFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    discountRelationCategoryFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return discountRelationCategoryFormFields
}

export const DiscountRelationProductFormFields = () => {
  const role = useSelector(getRole)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const [controller, setController] = useState();
  const signal = useRef();

  const asyncSelectRelationsLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'search': inputValue,
      'fields': ['name', 'coc', 'iban', 'bic', 'place', 'street', 'email', 'vatNumber', 'zipCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostRelationSearch({ token: token, admin_id: admin_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };
  
  const asyncSelectProductsLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'text': inputValue,
      'fuzziness': 2,
      'type': 'article',
      'fields': ['name', 'skuCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostProductSearch({ token: token, admin_id: admin_id, location_id: location_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };

  const discountRelationProductFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'async-select', name: 'Relation', id: 'relation_id', formdatafield: 'relation_id', required: true, loadOptions: asyncSelectRelationsLoadOptions },
    { type: 'async-select', name: 'Product', id: 'product_id', formdatafield: 'product_id', required: true, loadOptions: asyncSelectProductsLoadOptions },
    { type: 'object', name: `Discount Settings`, id: 'discount_settings', fields: DiscountSettingsFormFields() },
    { type: 'object', name: `Discount Values`, id: 'discount_values', fields: DiscountValuesFormFields() },
    { type: 'object', name: `Discount Limits`, id: 'discount_limits', fields: DiscountLimitsFormFields() }
  ]

  if (role === 'super' || role === 'admin') {
    discountRelationProductFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    discountRelationProductFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return discountRelationProductFormFields
}