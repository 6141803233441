import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import { DiscountProductLimitFormFields } from "./discountFormFields";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import { validateFormObject } from "../../helpers/validateFormObject";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { DiscountLimitCalculations, DiscountLimitTypes } from "../../selectfieldchoices/discountselect.mjs";
import useHandleError from "../../customhooks/useHandleError";
import DiscountProductLimitForm from "../../components/discountProducts/discountProductLimitForm";
import PostDiscountsProductLimits from "../../actablueAPI/discount/PostDiscountsProductLimits";
import { getDiscountProductLimit, setDiscountProductLimit } from "../../redux/dataSlices/discountSlice";
import { setActiveTab } from "../../redux/dataSlices/formTabSlice";

const DiscountProductLimitEdit = () => {
  const token = useSelector(getToken);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const discountProductLimit = useSelector(getDiscountProductLimit);
  const [invalidFields, setInvalidFields] = useState([]);
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newDiscountProductLimit = HandleOnChange({ event: event, object: discountProductLimit })
    dispatch(setDiscountProductLimit(newDiscountProductLimit))
  }

  const onChangeSelect = (event, selectFieldId) => {
    let newDiscountProductLimit = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: discountProductLimit, selectfield: selectFieldId })
    dispatch(setDiscountProductLimit(newDiscountProductLimit))
  }

  const onProductChange = (event) => {
    let newDiscountProductLimit = JSON.parse(JSON.stringify(discountProductLimit));
    newDiscountProductLimit.product_id = { id: event?.value, description: event?.label }
    if (event?.value === undefined || event?.label === undefined) newDiscountProductLimit.product_id = null
    dispatch(setDiscountProductLimit(newDiscountProductLimit))
  }

  const onCancel = () => {
    navigate(-1)
    dispatch(setActiveTab('main'));
  }

  const onSubmit = () => {
    const [invalid] = validateFormObject({ object: discountProductLimit, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    PostDiscountsProductLimits({ token: token, admin_id: discountProductLimit?.administration_id, data: discountProductLimit })
      .then(() => {
        navigate("/discount/products");
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit,
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: DiscountProductLimitFormFields(),
    field: {
      onChange: onChange
    },
    data: discountProductLimit,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === discountProductLimit.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: null,
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: null,
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    product_id: {
      label: 'product_id',
      placeholder: 'Search products...',
      noOptionsMessage: 'Type to search products...',
      options: [],
      selected: [],
      onChange: onProductChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: DiscountLimitTypes,
      selected: [],
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    calculation: {
      label: 'calculation',
      options: DiscountLimitCalculations,
      selected: [],
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: "Discount Product Limit edit",
    data: <DiscountProductLimitForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Discount Product"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};



export default DiscountProductLimitEdit;

