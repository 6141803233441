export default function HandleOnChangeMultiObjectField(props) {
  const event = props.event;
  const object = props.object || {};
  const field = props.field;

  const prefix = event?.target?.attributes?.prefix?.nodeValue || false;
  const elementId = event?.target?.attributes?.elementid?.nodeValue || prefix;

  let newObject = JSON.parse(JSON.stringify(object));

  if (!newObject.hasOwnProperty(field)) {
    newObject[field] = [];
  }

  if (prefix === 'add') {
    let newSubObject = {};
    newSubObject[event.target.name] = formatValue(event.target.type, event.target.value, event.target.checked);
    newObject[field].push(newSubObject);
  }

  if (!prefix) {
    newObject[field].forEach(element => {
      if (!element.hasOwnProperty('id')) {
        element[event.target.name] = formatValue(event.target.type, event.target.value, event.target.checked);
      }
    });
  }

  if (elementId) {
    newObject[field].forEach(element => {
      if (element.id?.toString() === elementId.toString()) {
        element[event.target.name] = formatValue(event.target.type, event.target.value, event.target.checked);
      }
    });
  }

  return newObject;
}

function formatValue(type, value, checked) {
  switch (type) {
    case 'checkbox':
      return checked ? checked : false;
    case 'number':
      return value === null || value === "" ? null : Number(value);
    default:
      return value;
  }
}
