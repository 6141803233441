import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import useHandleError from "../../customhooks/useHandleError";
import { useTranslation } from 'react-i18next';
import { getDiscountProductLimitPage, getDiscountProductLimitPageSize, getDiscountProductLimits, getDiscountProductPage, getDiscountProductPageSize, getDiscountProducts, setDiscountProductLimits, setDiscountProducts } from "../../redux/dataSlices/discountSlice";
import GetDiscountsProductsList from "../../actablueAPI/discount/GetDiscountsProductsList";
import DiscountProductsTable from "../../components/discountProducts/discountProductsTable";
import GetDiscountsProductLimitsList from "../../actablueAPI/discount/GetDiscountsProductLimitsList";
import DiscountProductLimitsTable from "../../components/discountProducts/discountProductLimitsTable";

const DiscountProducts = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const discountProducts = useSelector(getDiscountProducts);
  const discountProductLimits = useSelector(getDiscountProductLimits);
  const page = useSelector(getDiscountProductPage);
  const size = useSelector(getDiscountProductPageSize);
  const limitsPage = useSelector(getDiscountProductLimitPage);
  const limitsSize = useSelector(getDiscountProductLimitPageSize);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();
  const navTitle = "Discount"

  const discountProductsCard = {
    size: 12,
    title: "Product Discounts",
    data: location_id ? <DiscountProductsTable data={discountProducts} /> : translate('Please select a location'),
  };

  const discountProductLimitsCard = {
    size: 12,
    title: "Product Discount Limits",
    data: location_id ? <DiscountProductLimitsTable data={discountProductLimits} /> : translate('Please select a location'),
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [navTitle]);

  useEffect(() => {
    if (location_id) {

      const getDiscountProducts = async () => {
        try {
          const productResponse = await GetDiscountsProductsList({
            token,
            admin_id,
            location_id,
            page,
            size,
          });
          dispatch(setDiscountProducts(productResponse?.data));
        } catch (error) {
          HandleError({ error });
        }
      };

      const getDiscountProductLimits = async () => {
        try {
          const productLimitsResponse = await GetDiscountsProductLimitsList({
            token,
            admin_id,
            location_id,
            page: limitsPage,
            size: limitsSize,
          });
          dispatch(setDiscountProductLimits(productLimitsResponse?.data));
        } catch (error) {
          HandleError({ error });
        }
      };

      dispatch(setLoading(true))
      Promise.all([getDiscountProducts(), getDiscountProductLimits()])
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, page, size, limitsPage, limitsSize]);

  return (
    <>
      {scopes.discountproducts &&
        <>
          <BasicCard {...discountProductsCard} />
          <BasicCard {...discountProductLimitsCard} />
        </>
      }
    </>
  )
};
export default DiscountProducts;

