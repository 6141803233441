import { useEffect } from "react";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

const isInvalidField = (value) => {
  return value === null || value === undefined || value === "";
};

export default function MixMatchDiscountValuesEditTable(props) {
  const { t: translate } = useTranslation();

  let rows = [];
  let selected = props?.discount_values?.selected || [];

  useEffect(() => {
    if (!props?.data?.hasOwnProperty(props?.discount_values?.add?.name)) {
      return;
    }

    let anyFieldInvalid = props?.data?.[props?.discount_values?.add?.name]?.some(
      (element) =>
        isInvalidField(element?.required_quantity) || isInvalidField(element?.value)
    );

    props?.discount_values?.setInvalid(anyFieldInvalid);

    if (anyFieldInvalid) {
      props.setSubmitDisabledReason("Invalid field in Discount Values");
    }
    // eslint-disable-next-line
  }, [props?.data, props?.discount_values]);

  if (props?.data?.hasOwnProperty(props?.discount_values?.add?.name)) {
    props?.data?.[props?.discount_values?.add?.name]?.forEach((element) => {
      rows.push(
        <tr className="product-row" key={element.id}>
          <td>
            <Form.Check
              onChange={props?.discount_values?.check?.onChange}
              id={element.id}
              elementid={element.id}
            />
          </td>
          <td>
            <Form.Control
              prefix="discount_values"
              elementid={element.id}
              type="number"
              name="required_quantity"
              placeholder={translate("Required Quantity")}
              value={element.required_quantity}
              onChange={props?.discount_values?.onChange}
              isInvalid={isInvalidField(element?.required_quantity)}
            />
          </td>
          <td>
            <Form.Control
              prefix="discount_values"
              elementid={element.id}
              type="number"
              name="value"
              placeholder={translate("Value")}
              value={element.value}
              onChange={props?.discount_values?.onChange}
              isInvalid={isInvalidField(element?.value)}
            />
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <Table hover width="100%">
        <thead>
          <tr>
            <th />
            <th width="50%">{translate("Required Quantity")}</th>
            <th width="50%">{translate("Value")}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Button
        variant="primary"
        type={props?.discount_values?.add?.type}
        onClick={props?.discount_values?.add?.onClick}
        name={props?.discount_values?.add?.name}
      >
        {translate(props.discount_values.add.title)}
      </Button>
      <Button
        variant="primary"
        type={props?.discount_values?.remove?.type}
        onClick={props?.discount_values?.remove?.onClick}
        name={props?.discount_values?.remove?.name}
        disabled={selected.length === 0}
      >
        {translate(props?.discount_values?.remove?.title)}
      </Button>
    </>
  );
}
