
import { Modal, Col, Row, Button, ListGroup } from 'react-bootstrap';

import './css/modal.css'
import CreateFieldTemplate from '../helpers/createFieldTemplate';
import { useSelector } from 'react-redux';
import { getSelectedProducts } from '../redux/dataSlices/productSlice';
import { visibleChoices } from '../selectfieldchoices/productselect.mjs';
import { useTranslation } from 'react-i18next';


export default function ProductMultiEditModal(props) {
  const { t: translate } = useTranslation();
  const toggleModal = props?.toggleModal
  const onSaveClick = props?.onSaveClick
  const selectedProducts = useSelector(getSelectedProducts)
  const categoryKeys = Object.keys(selectedProducts);

  if (props.data?.hasOwnProperty('categories')) {
    // eslint-disable-next-line
    if (!props.data.categories.length === 0) props.journalcategories.selected = null

    if (props.data.categories.length >= 1) {
      // eslint-disable-next-line
      props.data.categories.map((category) => {
        if (category.type === 'JOURNAL') {
          props.journalcategories.selected = { 'value': category.id, 'label': category.name ? category.name : category.title }
        }
      })
    }
  }

  if (props.data?.hasOwnProperty('vat')) {
    props.vats.selected = { 'value': props.data.vat.id, 'label': props.data.vat.name ? props.data.vat.name : props.data.vat.title }
  }
  
  if (props.data?.hasOwnProperty('active')) {
    if (props?.data?.active) props.active.selected = { 'value': props.data.active, 'label': visibleChoices.find(element => element.id === props.data.active)?.name }
    if (!props?.data?.active) props.active.selected = null
  }

  if (props.data?.hasOwnProperty('ask_sn')) {
    if (props?.data?.ask_sn) props.ask_sn.selected = { 'value': props.data.ask_sn, 'label': visibleChoices.find(element => element.id === props.data.ask_sn)?.name }
    if (!props?.data?.ask_sn) props.ask_sn.selected = null
  }

  if (props.data?.hasOwnProperty('is_weighted')) {
    if (props?.data?.is_weighted) props.is_weighted.selected = { 'value': props.data.is_weighted, 'label': visibleChoices.find(element => element.id === props.data.is_weighted)?.name }
    if (!props?.data?.is_weighted) props.is_weighted.selected = null
  }

  if (props.data?.hasOwnProperty('input_required')) {
    if (props?.data?.input_required) props.input_required.selected = { 'value': props.data.input_required, 'label': visibleChoices.find(element => element.id === props.data.input_required)?.name }
    if (!props?.data?.input_required) props.input_required.selected = null
  }

  let basicFields = []
  props.fields?.forEach((field) => {
    basicFields.push(CreateFieldTemplate(field, props))
  });

  return (
    <Modal
      show={props?.show}
      size="xl"
      contentClassName='w-100 h-100'
      className='product-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className="product-modal-head" >
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='cst-modal-body'>
        <Row>
          <Col md={3}>
            <h6>{translate('First 10 selected products')}</h6>
            <ListGroup>
              {categoryKeys.slice(0, 10).map(key => (
                <ListGroup.Item key={key}>
                  {selectedProducts[key]?.name}
                </ListGroup.Item>
              ))}
              {categoryKeys.length > 10 &&
                <ListGroup.Item className='andmore-list-item' key='nmore'>
                  And {(categoryKeys.length - 10)} more...
                </ListGroup.Item>
              }
            </ListGroup>
          </Col>
          <Col md={'auto'}>
            <div className="vl"></div>
          </Col>
          <Col md={8}>
            <Row key={`fields-row`}>
              {basicFields}
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggleModal}>{translate('Close')}</Button>
        <Button variant="primary" onClick={onSaveClick}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

}