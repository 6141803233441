import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import useHandleError from "../../customhooks/useHandleError";
import { useTranslation } from 'react-i18next';
import { getDiscountRelationCategories, getDiscountRelationCategoryPage, getDiscountRelationCategoryPageSize, getDiscountRelationLimitPage, getDiscountRelationLimitPageSize, getDiscountRelationLimits, getDiscountRelationPage, getDiscountRelationPageSize, getDiscountRelationProductPage, getDiscountRelationProductPageSize, getDiscountRelationProducts, getDiscountRelations, setDiscountRelationCategories, setDiscountRelationLimits, setDiscountRelationProducts, setDiscountRelations } from "../../redux/dataSlices/discountSlice";
import GetDiscountsRelationsList from "../../actablueAPI/discount/GetDiscountsRelationsList";
import DiscountRelationsTable from "../../components/discountRelations/discountRelationsTable";
import GetDiscountsRelationLimitsList from "../../actablueAPI/discount/GetDiscountsRelationLimitsList";
import DiscountRelationLimitsTable from "../../components/discountRelations/discountRelationLimitsTable";
import GetDiscountsRelationCategoriesList from "../../actablueAPI/discount/GetDiscountsRelationCategoriesList";
import DiscountRelationCategoriesTable from "../../components/discountRelations/discountRelationCategoriesTable";
import GetDiscountsRelationProductsList from "../../actablueAPI/discount/GetDiscountsRelationProductsList";
import DiscountRelationProductsTable from "../../components/discountRelations/discountRelationProductsTable";

const DiscountRelations = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const discountRelations = useSelector(getDiscountRelations);
  const discountRelationLimits = useSelector(getDiscountRelationLimits);
  const discountRelationCategories = useSelector(getDiscountRelationCategories);
  const discountRelationProducts = useSelector(getDiscountRelationProducts);
  const page = useSelector(getDiscountRelationPage);
  const size = useSelector(getDiscountRelationPageSize);
  const limitsPage = useSelector(getDiscountRelationLimitPage);
  const limitsSize = useSelector(getDiscountRelationLimitPageSize);
  const categoriesPage = useSelector(getDiscountRelationCategoryPage);
  const categoriesSize = useSelector(getDiscountRelationCategoryPageSize);
  const productsPage = useSelector(getDiscountRelationProductPage);
  const productsSize = useSelector(getDiscountRelationProductPageSize);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();
  const navTitle = "Discount"

  const discountRelationsCard = {
    size: 12,
    title: "Relation Discounts",
    data: location_id ? <DiscountRelationsTable data={discountRelations} /> : translate('Please select a location'),
  };

  const discountRelationLimitsCard = {
    size: 12,
    title: "Relation Discount Limits",
    data: location_id ? <DiscountRelationLimitsTable data={discountRelationLimits} /> : translate('Please select a location'),
  };

  const discountRelationCategoriesCard = {
    size: 12,
    title: "Relation Category Discounts",
    data: location_id ? <DiscountRelationCategoriesTable data={discountRelationCategories} /> : translate('Please select a location'),
  };

  const discountRelationProductsCard = {
    size: 12,
    title: "Relation Product Discounts",
    data: location_id ? <DiscountRelationProductsTable data={discountRelationProducts} /> : translate('Please select a location'),
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [navTitle]);

  useEffect(() => {
    if (location_id) {

      const getDiscountRelations = async () => {
        try {
          const relationResponse = await GetDiscountsRelationsList({
            token,
            admin_id,
            location_id,
            page,
            size,
          });
          dispatch(setDiscountRelations(relationResponse?.data));
        } catch (error) {
          HandleError({ error });
        }
      };

      const getDiscountRelationLimits = async () => {
        try {
          const relationLimitsResponse = await GetDiscountsRelationLimitsList({
            token,
            admin_id,
            location_id,
            page: limitsPage,
            size: limitsSize,
          });
          dispatch(setDiscountRelationLimits(relationLimitsResponse?.data));
        } catch (error) {
          HandleError({ error });
        }
      };

      const getDiscountRelationCategories = async () => {
        try {
          const relationResponse = await GetDiscountsRelationCategoriesList({
            token,
            admin_id,
            location_id,
            page: categoriesPage,
            size: categoriesSize,
          });
          dispatch(setDiscountRelationCategories(relationResponse?.data));
        } catch (error) {
          HandleError({ error });
        }
      };

      const getDiscountRelationProducts = async () => {
        try {
          const relationResponse = await GetDiscountsRelationProductsList({
            token,
            admin_id,
            location_id,
            page: productsPage,
            size: productsSize,
          });
          dispatch(setDiscountRelationProducts(relationResponse?.data));
        } catch (error) {
          HandleError({ error });
        }
      };

      dispatch(setLoading(true))
      Promise.all([getDiscountRelations(), getDiscountRelationLimits(), getDiscountRelationCategories(), getDiscountRelationProducts()])
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, page, size, limitsPage, limitsSize, categoriesPage, categoriesSize, productsPage, productsSize]);

  return (
    <>
      {scopes.discountrelations &&
        <>
          <BasicCard {...discountRelationsCard} />
          <BasicCard {...discountRelationLimitsCard} />
          <BasicCard {...discountRelationCategoriesCard} />
          <BasicCard {...discountRelationProductsCard} />
        </>
      }
    </>
  )
};
export default DiscountRelations;

