import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import { DiscountRelationCategoryFormFields, DiscountRelationProductFormFields } from "./discountFormFields";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import { validateFormObject } from "../../helpers/validateFormObject";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import HandleOnChangeMultiObjectField from "../../helpers/handleOnChangeMultiObjectFields";
import AddObject from "../../helpers/addObject";
import RemoveObject from "../../helpers/removeObject";
import HandleOnChangeCheckbox from "../../helpers/handleOnChangeCheckbox";
import CheckNewObjects from "../../helpers/checkNewObjects";
import { DiscountLimitCalculations, DiscountLimitTypes, DiscountSettingsCalculations, DiscountSettingsTypes } from "../../selectfieldchoices/discountselect.mjs";
import useHandleError from "../../customhooks/useHandleError";
import HandleOnChangeMultiObjectSingleSelectFieldWithValue from "../../helpers/handleOnChangeMultiObjectSingleSelectFieldWithValue";
import DiscountRelationCategoryForm from "../../components/discountRelations/discountRelationCategoryForm";
import PostDiscountsRelationCategories from "../../actablueAPI/discount/PostDiscountsRelationCategories";
import { getDiscountRelationCategory, setDiscountRelationCategory } from "../../redux/dataSlices/discountSlice";
import { setActiveTab } from "../../redux/dataSlices/formTabSlice";
import { getProductCategoriesAll, setProductCategoriesAll } from "../../redux/dataSlices/categorySlice";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";

const DiscountRelationCategoryEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const categoriesAll = useSelector(getProductCategoriesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState(0);
  const discountRelationCategory = useSelector(getDiscountRelationCategory);
  const [checkedList, setCheckedList] = useState([]);
  const [invalidFields, setInvalidFields] = useState([]);
  const [discountValuesInvalid, setDiscountValuesInvalid] = useState(false);
  const [discountLimitsInvalid, setDiscountLimitsInvalid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitDisabledReason, setSubmitDisabledReason] = useState("");
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newDiscountRelationCategory = HandleOnChange({ event: event, object: discountRelationCategory })
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onChangeDiscountValues = (event) => {
    let newDiscountRelationCategory = HandleOnChangeMultiObjectField({ event: event, object: discountRelationCategory, field: 'discount_values' })
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onChangeDiscountLimits = (event) => {
    let newDiscountRelationCategory = HandleOnChangeMultiObjectField({ event: event, object: discountRelationCategory, field: 'discount_limits' })
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onChangeSelect = (event, selectFieldId) => {
    let newDiscountRelationCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: discountRelationCategory, selectfield: selectFieldId })
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onRelationChange = (event) => {
    let newDiscountRelationCategory = JSON.parse(JSON.stringify(discountRelationCategory));
    newDiscountRelationCategory.relation_id = { id: event?.value, description: event?.label }
    if (event?.value === undefined || event?.label === undefined) newDiscountRelationCategory.relation_id = null
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onCategoryChange = (event) => {
    let newDiscountRelationCategory = JSON.parse(JSON.stringify(discountRelationCategory));
    newDiscountRelationCategory.category_id = { id: event?.value, description: event?.label }
    if (event?.value === undefined || event?.label === undefined) newDiscountRelationCategory.category_id = null
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onAddObject = (event) => {
    let newId = id + 1
    setId(newId)
    let newDiscountRelationCategory = AddObject({ event: event, object: discountRelationCategory, id: newId })
    if (event?.target?.name === 'discount_limits') newDiscountRelationCategory[event?.target?.name].find(obj => obj.id === newId.toString()).active = true;
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onRemoveObjects = (event) => {
    let newDiscountRelationCategory = RemoveObject({ event: event, object: discountRelationCategory, checkedList: checkedList })
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
    setCheckedList([])
  }

  const onChangeCheckbox = (event) => {
    let newCheckedList = HandleOnChangeCheckbox({ event: event, object: checkedList })
    setCheckedList(newCheckedList)
  }

  const onTypeChange = (event) => {
    let newDiscountRelationCategory = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: discountRelationCategory, selectfield: 'type', field: 'discount_limits' })
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onCalculationChange = (event) => {
    let newDiscountRelationCategory = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: discountRelationCategory, selectfield: 'calculation', field: 'discount_limits' })
    dispatch(setDiscountRelationCategory(newDiscountRelationCategory))
  }

  const onCancel = () => {
    navigate(-1)
    dispatch(setActiveTab('main'));
  }

  const onSubmit = () => {
    const [invalid] = validateFormObject({ object: discountRelationCategory, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    let newObject = JSON.parse(JSON.stringify(discountRelationCategory));

    newObject = CheckNewObjects(discountRelationCategory)

    if (newObject?.discount_settings?.start === '') newObject.discount_settings.start = null;
    if (newObject?.discount_settings?.end === '') newObject.discount_settings.end = null;

    dispatch(setLoading(true))

    PostDiscountsRelationCategories({ token: token, admin_id: discountRelationCategory?.administration_id, data: newObject })
      .then(() => {
        navigate("/discount/relations");
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit,
      disabled: submitDisabled,
      disabledReason: submitDisabledReason
    },
    setSubmitDisabled: setSubmitDisabled,
    setSubmitDisabledReason: setSubmitDisabledReason,
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: DiscountRelationCategoryFormFields(),
    field: {
      onChange: onChange
    },
    data: discountRelationCategory,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === discountRelationCategory.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: null,
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: null,
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    relation_id: {
      label: 'relation_id',
      placeholder: 'Search relations...',
      noOptionsMessage: 'Type to search relations...',
      options: [],
      selected: [],
      onChange: onRelationChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    category_id: {
      label: 'category_id',
      options: categoriesAll,
      selected: [],
      onChange: onCategoryChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: DiscountSettingsTypes,
      selected: [],
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    calculation: {
      label: 'calculation',
      options: DiscountSettingsCalculations,
      selected: [],
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    discount_values: {
      setInvalid: setDiscountValuesInvalid,
      onChange: onChangeDiscountValues,
      add: {
        title: 'Add',
        type: 'button',
        name: 'discount_values',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'discount_values',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
      },
      selected: checkedList
    },
    discount_limits: {
      setInvalid: setDiscountLimitsInvalid,
      onChange: onChangeDiscountLimits,
      add: {
        title: 'Add',
        type: 'button',
        name: 'discount_limits',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'discount_limits',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
      },
      selected: checkedList,
      type: {
        label: 'type',
        options: DiscountLimitTypes,
        onChange: onTypeChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      },
      calculation: {
        label: 'calculation',
        options: DiscountLimitCalculations,
        onChange: onCalculationChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      }
    },
  };

  const editCard = {
    size: 12,
    title: "Discount Relation Category edit",
    data: <DiscountRelationCategoryForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Discount Relation"))

    dispatch(setLoading(true));
    GetAllCategoriesWithType({ type: 'PRODUCTPAGE', token: token, admin_id: admin_id, location_id: location_id })
      .then(async (response) => {
        dispatch(setProductCategoriesAll(response))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        navigate(-1);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (discountLimitsInvalid || discountValuesInvalid) {
      setSubmitDisabled(true);
      return
    }
    setSubmitDisabled(false)
    // eslint-disable-next-line
  }, [discountLimitsInvalid, discountValuesInvalid])

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};



export default DiscountRelationCategoryEdit;