const CheckNewObjects = (object) => {
  let newObject = JSON.parse(JSON.stringify(object))
  const keys = [
    "phonenumbers",
    "phone_numbers",
    "addresses",
    "emails",
    "next_device_sort_groups",
    "discount_values",
    "discount_limits"
  ];

  keys.forEach((key) => {
    if (newObject?.[key]) {
      newObject[key].forEach((item) => {
        if (item?.new) {
          delete item.new;
          delete item.id;
        }
      });
    }
  });

  return newObject
};

export default CheckNewObjects;
